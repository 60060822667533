import { useState, useEffect } from 'react';
import NumberFormat from 'react-number-format';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Collapse from '@material-ui/core/Collapse';
import { spacing } from '@material-ui/system';
import { apiClient } from '@services/api';
import Moment from 'react-moment';
import Icon from '@material-ui/core/Icon';
import Checkbox from '@components/Checkbox';
import ChangeStateModal from './ChangeStateModal';

const permissions = ['administrador'];

const TableBodyData = (props) => {

  const [open, setOpen] = useState(false)
  const [tours, setTours] = useState([])
  const types = {
    tour: 'Passeio',
    transfer: 'Transfer',
  }
  const status = {
    canceled: 'Cancelado',
    pending: 'Pendente',
    sended: 'Habilitado',
  }

  useEffect(() => {
    getTours()
  }, [])

  const getTours = () => {
    apiClient.httpInstance.get(`/tours?pageSize=500`).then(response => {
      if (response.status === 200) {
        //console.log('tours', response.data)
        setTours(response.data.data)
      }
    })
      .catch(error => {
        if (error) {
          console.log(error)
        }
      });
  }

  const showFreeValue = (value, total) => {
    if (props.reserve?.region_id != '81' && props.reserve?.region_id != '79') {
      return value
    }
    const tour_date = new Date(props.reserve?.tour_date)
    const today = new Date()
    if ((tour_date < today) && !(tour_date > new Date('2024-02-29') && tour_date < new Date('2024-04-01')) && (props.reserve?.region_id != '79')) {
      return value
    } else {
      const selectedTour = tours && tours?.find(tour => tour?.name?.match(props.reserve?.travel_name))
      //selectedTour?.name == 'PIEDRAS ROJAS' && console.log('selectedTour', selectedTour)
      if(!selectedTour) return value
      if (total) {
        return Number(selectedTour?.price) * (Number(props.reserve?.adults_total) + Number(props.reserve?.kids_total))
      } else {
        return selectedTour?.price
      }
    }
  }

  let partnerComission = props.reserve.partner_seller_id && props.reserve.partner_seller_type ? props.reserve.percentage_commission : props.reserve.percentage_commission_partner
  let totalOperationalCostPartner = (showFreeValue(props.reserve.reservation_brute_value) - parseFloat(props.reserve.operational_cost_total)) * partnerComission / 100 || 0
  let totalOperationalCostSale = (showFreeValue(props.reserve.reservation_brute_value) - parseFloat(props.reserve.operational_cost_total_sale)) * (props.reserve.percentage_commission_region ? props.reserve.percentage_commission_region : props.reserve.max_percentage_commission || 0) / 100 || 0
  let totalOperationalCostTourView = 0
  if (props.reserve.tour_date < '2023-09-15') {
    //Comissões anteriores a 15/09/2023
    if (props.reserve.partner_seller_id && props.reserve.partner_seller_type) {
      totalOperationalCostTourView = parseFloat(props.reserve.operational_cost_total)
    } else {
      if (props.reserve.partner_seller_id) {
        if (parseInt(props.reserve.operational_cost_total_sale) > 0) {
          totalOperationalCostTourView = parseFloat(props.reserve.operational_cost_total_sale)
        } else {
          totalOperationalCostTourView = parseFloat(props.reserve.operational_cost_total_sale) + parseFloat(props.reserve.operational_cost_total)
        }
      } else {
        totalOperationalCostTourView = parseFloat(props.reserve.operational_cost_total_sale) + parseFloat(props.reserve.operational_cost_total)
      }
    }
  } else {
    //Comissões posteriores a 15/09/2023, novo cálculo com separação do valor de venda e de parceiro em cada passeio
    if (props.reserve.partner_seller_id && props.reserve.partner_seller_type) {
      totalOperationalCostTourView = parseFloat(props.reserve.operational_cost_total)
    } else {
      if (props.reserve.partner_seller_id) {
        if (parseInt(props.reserve.operational_cost_total_sale) > 0) {
          totalOperationalCostTourView = parseFloat(props.reserve.operational_cost_total_sale) + totalOperationalCostPartner
        } else {
          totalOperationalCostTourView = /* parseFloat(props.reserve.operational_cost_total_sale) +  */parseFloat(props.reserve.operational_cost_total)
        }
      } else {
        totalOperationalCostTourView = parseFloat(props.reserve.operational_cost_total_sale)/*  + parseFloat(props.reserve.operational_cost_total) */
      }
    }
  }

  return (
    <>
      {!props.print &&
        <>
          <TableRow key={props.reserve.id} className="mb-7" onClick={() => setOpen(!open)}>
            <TableCell className="group py-2.5">
              {permissions.includes(props?.permission) && <Checkbox
                className={!props.reserve.select ? "hidden p-0 group-hover:block" : "p-0"}
                value={props.reserve.select}
                checked={props.reserve.select}
                onChange={(ev) => props.changeCheckbox(ev.target.checked, false, props.idx)}
                color="dark"
              />}
              {props.reserve.sale_status === 'canceled' &&
                <Icon className="mr-2 text-red-500">block</Icon>
              }{props.reserve.references}
            </TableCell>
            <TableCell className="py-2.5">
              {props.reserve.company_name}
            </TableCell>
            <TableCell className="py-2.5">
              {props.reserve.customer_name}
            </TableCell>
            <TableCell className="py-2.5">
              {props.reserve.travel_name}
              {props.reserve.transfer_type === 'in' ?
                <span className="items-center justify-center rounded-full ml-1 h-8 w-12 text-white" style={{ background: '#009C86E5' }}>
                  In
                </span>
                :
                ''
              }
              {props.reserve.transfer_type === 'out' ?
                <span className="items-center justify-center rounded-full ml-1 h-8 w-12 text-white" style={{ background: '#4480A8E5' }}>
                  Out
                </span>
                :
                ''
              }
            </TableCell>
            <TableCell className="py-2.5">
              {props.reserve.payment_status === 'pending' || props.reserve.payment_status === 'partial' ?
                <>
                  Pendente
                  <Icon
                    className="ml-2 cursor-pointer"
                    fontSize="small"
                    onClick={() => setOpen(!open)}
                  >do_disturb_on
                  </Icon>
                </>
                : props.reserve.payment_status === 'accomplished' ?
                  <>
                    Realizado
                    <Icon
                      className="ml-2 check_circle"
                      fontSize="small"
                      onClick={() => setOpen(!open)}
                    >check_circle
                    </Icon>
                  </>
                  : ''}

            </TableCell>
            <TableCell className="py-2.5">
              <div className="flex items-center pr-4 space-x-5">
                <Moment format="DD/MM/YYYY" date={props.reserve.tour_date} />
              </div>
            </TableCell>
            {/* Valor Livre */}
            {props.travelType !== 'transfer' ?
              <TableCell className="py-2.5">
                {props.type === 'seller' ?
                  <NumberFormat
                    //value={props.reserve.status !== 'canceled' ? Number((showFreeValue(props.reserve.reservation_brute_value) - (showFreeValue(props.reserve.reservation_brute_value) - parseFloat(props.reserve.operational_cost_total)) * (props.reserve.partner_seller_id && props.reserve.partner_seller_type ? props.reserve.percentage_commission : props.reserve.percentage_commission_partner) / 100)) : 0 || 0}
                    value={props.reserve.status !== 'canceled' ? Number(showFreeValue(props.reserve.reservation_brute_value, true) - totalOperationalCostTourView) : 0 || 0}
                    //value={props.reserve.status !== 'canceled' ? Number((showFreeValue(props.reserve.reservation_brute_value) - totalOperationalCostTourView) - ((showFreeValue(props.reserve.reservation_brute_value) - totalOperationalCostTourView) * partnerComission / 100)) : 0 || 0}
                    displayType={'text'}
                    decimalSeparator={","}
                    decimalScale={2}
                    fixedDecimalScale={true}
                    decimalPrecision={2}
                    thousandSeparator={"."}
                    isNumericString
                    prefix={`${process.env.REACT_APP_CURRENCY} `}
                    renderText={(value) => <span className="flex items-center">{value}</span>}
                  /> :
                  <NumberFormat
                    value={props.reserve.status !== 'canceled' ? Number((props.reserve.partner_commission_type === true ? showFreeValue(props.reserve.reservation_brute_value, true) - props.reserve.operational_cost_total : showFreeValue(props.reserve.reservation_brute_value, true))) : 0 || 0}
                    displayType={'text'}
                    decimalSeparator={","}
                    decimalScale={2}
                    fixedDecimalScale={true}
                    decimalPrecision={2}
                    thousandSeparator={"."}
                    isNumericString
                    prefix={`${process.env.REACT_APP_CURRENCY} `}
                    renderText={(value) => <span className="flex items-center">{value}</span>}
                  />
                }
              </TableCell>
              :
              <TableCell className="py-2.5">
                {props.type === 'seller' ?
                  <NumberFormat
                    value={props.reserve.status !== 'canceled' ? Number(props.reserve.percentage_commission_region) : 0 || 0}
                    displayType={'text'}
                    decimalSeparator={","}
                    decimalScale={2}
                    fixedDecimalScale={true}
                    decimalPrecision={2}
                    thousandSeparator={"."}
                    isNumericString
                    prefix={`${process.env.REACT_APP_CURRENCY} `}
                    renderText={(value) => <span className="flex items-center">{value}</span>}
                  />
                  :
                  <NumberFormat
                    value={props.reserve.status !== 'canceled' ? Number(props.reserve.percentage_commission) : 0 || 0}
                    displayType={'text'}
                    decimalSeparator={","}
                    decimalScale={2}
                    fixedDecimalScale={true}
                    decimalPrecision={2}
                    thousandSeparator={"."}
                    isNumericString
                    prefix={`${process.env.REACT_APP_CURRENCY} `}
                    renderText={(value) => <span className="flex items-center">{value}</span>}
                  />
                }
              </TableCell>
            }
            {props.travelType !== 'transfer' ?
              <>
                <TableCell className="py-2.5">
                  {props.reserve.type === 'tour' ?
                    props.type === 'seller' ?
                      <NumberFormat
                        //value={props.reserve.status !== 'canceled' ? Number(((showFreeValue(props.reserve.reservation_brute_value) - (showFreeValue(props.reserve.reservation_brute_value) - parseFloat(props.reserve.operational_cost_total)) * (props.reserve.partner_seller_id && props.reserve.partner_seller_type ? props.reserve.percentage_commission : props.reserve.percentage_commission_partner) / 100 || 0)) * (props.reserve.percentage_commission_region ? props.reserve.percentage_commission_region : props.reserve.max_percentage_commission) / 100) : 0 || 0}
                        value={props.reserve.status !== 'canceled' ? Number(((showFreeValue(props.reserve.reservation_brute_value, true) - totalOperationalCostTourView)) * (props.reserve.percentage_commission_region ? props.reserve.percentage_commission_region : props.reserve.max_percentage_commission) / 100) : 0 || 0}
                        displayType={'text'}
                        decimalSeparator={","}
                        decimalScale={2}
                        fixedDecimalScale={true}
                        decimalPrecision={2}
                        thousandSeparator={"."}
                        isNumericString
                        prefix={`${process.env.REACT_APP_CURRENCY} `}
                        renderText={(value) => <span className="flex items-center">{value}</span>}
                      />
                      :
                      <NumberFormat
                        value={props.reserve.status !== 'canceled' ? Number(props.reserve.partner_commission_type === true ? (showFreeValue(props.reserve.reservation_brute_value) - props.reserve.operational_cost_total) * (props.reserve.percentage_commission / 100) : (showFreeValue(props.reserve.reservation_brute_value)) * (props.reserve.percentage_commission / 100)) : 0 || 0}
                        displayType={'text'}
                        decimalSeparator={","}
                        decimalScale={2}
                        fixedDecimalScale={true}
                        decimalPrecision={2}
                        thousandSeparator={"."}
                        isNumericString
                        prefix={`${process.env.REACT_APP_CURRENCY} `}
                        renderText={(value) => <span className="flex items-center">{value}</span>}
                      />
                    :
                    props.type === 'seller' ?
                      <NumberFormat
                        value={props.reserve.status !== 'canceled' ? Number(props.reserve.percentage_commission_region) : 0 || 0}
                        displayType={'text'}
                        decimalSeparator={","}
                        decimalScale={2}
                        fixedDecimalScale={true}
                        decimalPrecision={2}
                        thousandSeparator={"."}
                        isNumericString
                        prefix={`${process.env.REACT_APP_CURRENCY} `}
                        renderText={(value) => <span className="flex items-center">{value}</span>}
                      />
                      :
                      <NumberFormat
                        value={props.reserve.status !== 'canceled' ? Number(props.reserve.percentage_commission) : 0 || 0}
                        displayType={'text'}
                        decimalSeparator={","}
                        decimalScale={2}
                        fixedDecimalScale={true}
                        decimalPrecision={2}
                        thousandSeparator={"."}
                        isNumericString
                        prefix={`${process.env.REACT_APP_CURRENCY} `}
                        renderText={(value) => <span className="flex items-center">{value}</span>}
                      />
                  }
                </TableCell>
              </>
              :
              ''
            }
            <TableCell className="items-center justify-between py-2.5">
              {status[props.reserve.status] || 'Pendente'}
            </TableCell>
            <TableCell className="items-center justify-between p-0">
              <Icon
                className="ml-2 cursor-pointer"
                onClick={() => setOpen(!open)}
              >
                {open === true ? 'keyboard_arrow_up' : 'keyboard_arrow_down'}
              </Icon>
              {
                props.reserve.select && !props.checkAll &&
                <Icon
                  className="ml-2 cursor-pointer text-lightblue"
                  onClick={() => props.setOpenModal()}
                >
                  editar
                </Icon>
              }
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell className={`py-0 ${!open ? 'border-0' : ''}`} colSpan={6}>
              <Collapse in={open} timeout="auto" unmountOnExit>
                <div className="flex py-4">
                  <div className="flex-1 font-semibold">
                    Valor bruto
                  </div>
                  {props.reserve.type === 'tour' && props.type == 'seller' ?
                    <div className="flex-1 font-semibold">
                      Parceiro
                    </div>
                    : ''}
                  <div className="flex-1 font-semibold">
                    Custo operacional
                  </div>
                  <div className="flex-1 font-semibold">
                    {props.reserve.type === 'tour' ?
                      'Porcentagem de comissão'
                      :
                      'Comissão fixa'
                    }
                  </div>
                </div>
                <div className="flex items-center py-4">
                  <div className="flex-1">
                    <div className="flex items-center">
                      <div className="flex-1 ml-2">
                        <NumberFormat
                          value={Number(showFreeValue(props.reserve.reservation_brute_value, true)) || 0}
                          displayType={'text'}
                          decimalSeparator={","}
                          decimalScale={2}
                          fixedDecimalScale={true}
                          decimalPrecision={2}
                          thousandSeparator={"."}
                          isNumericString
                          prefix={`${process.env.REACT_APP_CURRENCY} `}
                          renderText={(value) => <span className="flex items-center">{value}</span>}
                        />
                      </div>
                      {/* PARA PASSEIOS */}
                      {props.reserve.type === 'tour' && props.type === 'seller' ?
                        <div className="flex-1 ml-2">
                          <NumberFormat
                            value={Number(totalOperationalCostPartner) || 0}
                            displayType={'text'}
                            decimalSeparator={","}
                            decimalScale={2}
                            fixedDecimalScale={true}
                            decimalPrecision={2}
                            thousandSeparator={"."}
                            isNumericString
                            prefix={`${process.env.REACT_APP_CURRENCY} `}
                            renderText={(value) => <span className="flex items-center">{value}</span>}
                          />
                        </div>
                        : ''}
                      <div className="flex-1 ml-2">
                        {/* PARA TRANSFERS */}
                        {props.reserve.type === 'tour' ? <NumberFormat
                          //value={Number(!props.reserve.partner_seller_type ? totalOperationalCostTourView : totalOperationalCostPartner) || 0}
                          value={Number(totalOperationalCostTourView) || 0}
                          displayType={'text'}
                          decimalSeparator={","}
                          decimalScale={2}
                          fixedDecimalScale={true}
                          decimalPrecision={2}
                          thousandSeparator={"."}
                          isNumericString
                          prefix={`${process.env.REACT_APP_CURRENCY} `}
                          renderText={(value) => <span className="flex items-center">{value}</span>}
                        /> :
                          <NumberFormat
                            value={Number(props.reserve.operational_cost_reserve) || 0}
                            displayType={'text'}
                            decimalSeparator={","}
                            decimalScale={2}
                            fixedDecimalScale={true}
                            decimalPrecision={2}
                            thousandSeparator={"."}
                            isNumericString
                            prefix={`${process.env.REACT_APP_CURRENCY} `}
                            renderText={(value) => <span className="flex items-center">{value}</span>}
                          />}
                      </div>
                      <div className="flex-1 ml-2">
                        {props.reserve.type === 'tour' ?
                          props.type === 'seller' ?
                            <span>{props.reserve.status !== 'canceled' && props.reserve.percentage_commission_region ? props.reserve.percentage_commission_region : props.reserve.max_percentage_commission || 0}%</span>
                            :
                            <span>{props.reserve.percentage_commission || 0}%</span>
                          :
                          props.type === 'seller' ?
                            <span>{props.reserve.status !== 'canceled' && props.reserve.percentage_commission_region || 0}</span>
                            :
                            <span>{props.reserve.percentage_commission || 0}</span>
                        }
                      </div>
                    </div>
                  </div>
                </div>
              </Collapse>
            </TableCell>
          </TableRow>
        </>
      }

      {props.print && props.travelType === 'transfer' && (props.reserve.company_id == props.company || props.company == 'all' || props.company == undefined) &&
        <TableRow key={props.reserve.id}>
          <TableCell className="py-2.5" style={{ fontSize: '0.7rem', padding: '0.4rem' }}>
            {props.reserve.references}
          </TableCell>
          <TableCell className="py-2.5" style={{ fontSize: '0.7rem', padding: '0.4rem' }}>
            {props.reserve.company_name}
          </TableCell>
          <TableCell className="py-2.5" style={{ fontSize: '0.7rem', padding: '0.4rem' }}>
            {props.reserve.customer_name}
          </TableCell>
          <TableCell className="py-2.5" style={{ fontSize: '0.7rem', padding: '0.4rem' }}>
            {props.reserve.travel_name} - {props.reserve.transfer_type}
          </TableCell>
          <TableCell className="py-2.5" style={{ fontSize: '0.7rem', padding: '0.4rem' }}>
            {props.reserve.payment_status === 'pending' || props.reserve.payment_status === 'partial' ?
              <>
                Pendente
              </>
              : props.reserve.payment_status === 'accomplished' ?
                <>
                  Realizado
                </>
                : ''}
          </TableCell>
          <TableCell className="py-2.5" style={{ fontSize: '0.7rem', padding: '0.4rem' }}>
            {<Moment format="DD/MM/YYYY" date={props.reserve.tour_date} />}
          </TableCell>
          <TableCell className="py-2.5" style={{ fontSize: '0.7rem', padding: '0.4rem' }}>
            {
              props.type === 'seller' ?
                <NumberFormat
                  value={props.reserve.status !== 'canceled' ? Number(props.reserve.percentage_commission_region) : 0 || 0}
                  displayType={'text'}
                  decimalSeparator={","}
                  decimalScale={2}
                  fixedDecimalScale={true}
                  decimalPrecision={2}
                  thousandSeparator={"."}
                  isNumericString
                  prefix={`${process.env.REACT_APP_CURRENCY} `}
                  renderText={(value) => <span className="flex items-center">{value}</span>}
                />
                :
                <NumberFormat
                  value={props.reserve.status !== 'canceled' ? Number(props.reserve.percentage_commission) : 0 || 0}
                  displayType={'text'}
                  decimalSeparator={","}
                  decimalScale={2}
                  fixedDecimalScale={true}
                  decimalPrecision={2}
                  thousandSeparator={"."}
                  isNumericString
                  prefix={`${process.env.REACT_APP_CURRENCY} `}
                  renderText={(value) => <span className="flex items-center">{value}</span>}
                />
            }
          </TableCell>
          {props.travelType !== 'transfer' ? <>
            <TableCell className="py-2.5" style={{ fontSize: '0.7rem', padding: '0.4rem' }}>
              {props.reserve.type === 'tour' ?
                props.type === 'seller' ?
                  <NumberFormat
                    value={Number(((showFreeValue(props.reserve.reservation_brute_value) - totalOperationalCostTourView) - ((showFreeValue(props.reserve.reservation_brute_value) - totalOperationalCostTourView) * partnerComission / 100)) * (props.reserve.percentage_commission_region ? props.reserve.percentage_commission_region : props.reserve.max_percentage_commission) / 100) || 0}
                    displayType={'text'}
                    decimalSeparator={","}
                    decimalScale={2}
                    fixedDecimalScale={true}
                    decimalPrecision={2}
                    thousandSeparator={"."}
                    isNumericString
                    prefix={`${process.env.REACT_APP_CURRENCY} `}
                    renderText={(value) => <span className="flex items-center">{value}</span>}
                  />
                  :
                  <NumberFormat
                    value={Number(props.reserve.partner_commission_type === true ? (showFreeValue(props.reserve.reservation_brute_value) - props.reserve.operational_cost_total) * (props.reserve.percentage_commission / 100) : (showFreeValue(props.reserve.reservation_brute_value)) * (props.reserve.percentage_commission / 100)) || 0}
                    displayType={'text'}
                    decimalSeparator={","}
                    decimalScale={2}
                    fixedDecimalScale={true}
                    decimalPrecision={2}
                    thousandSeparator={"."}
                    isNumericString
                    prefix={`${process.env.REACT_APP_CURRENCY} `}
                    renderText={(value) => <span className="flex items-center">{value}</span>}
                  />
                :
                props.type === 'seller' ?
                  <NumberFormat
                    value={Number(props.reserve.percentage_commission_region) || 0}
                    displayType={'text'}
                    decimalSeparator={","}
                    decimalScale={2}
                    fixedDecimalScale={true}
                    decimalPrecision={2}
                    thousandSeparator={"."}
                    isNumericString
                    prefix={`${process.env.REACT_APP_CURRENCY} `}
                    renderText={(value) => <span className="flex items-center">{value}</span>}
                  />
                  :
                  <NumberFormat
                    value={Number(props.reserve.percentage_commission) || 0}
                    displayType={'text'}
                    decimalSeparator={","}
                    decimalScale={2}
                    fixedDecimalScale={true}
                    decimalPrecision={2}
                    thousandSeparator={"."}
                    isNumericString
                    prefix={`${process.env.REACT_APP_CURRENCY} `}
                    renderText={(value) => <span className="flex items-center">{value}</span>}
                  />
              }
            </TableCell>
          </> : ''}
          <TableCell className="py-2.5" style={{ fontSize: '0.7rem', padding: '0.4rem' }}>
            {status[props.reserve.status] || 'Pendente'}
          </TableCell>
          <TableCell className="py-2.5" style={{ fontSize: '0.7rem', padding: '0.4rem' }}>
            <NumberFormat
              value={Number(showFreeValue(props.reserve.reservation_brute_value)) || 0}
              displayType={'text'}
              decimalSeparator={","}
              decimalScale={2}
              fixedDecimalScale={true}
              decimalPrecision={2}
              thousandSeparator={"."}
              isNumericString
              prefix={`${process.env.REACT_APP_CURRENCY} `}
              renderText={(value) => <span className="flex items-center">{value}</span>}
            />
          </TableCell>
          {props.reserve.type === 'tour' ?
            <TableCell className="py-2.5" style={{ fontSize: '0.7rem', padding: '0.4rem' }}>
              <NumberFormat
                value={Number((showFreeValue(props.reserve.reservation_brute_value) - totalOperationalCostTourView) * partnerComission / 100) || 0}
                displayType={'text'}
                decimalSeparator={","}
                decimalScale={2}
                fixedDecimalScale={true}
                decimalPrecision={2}
                thousandSeparator={"."}
                isNumericString
                prefix={`${process.env.REACT_APP_CURRENCY} `}
                renderText={(value) => <span className="flex items-center">{value}</span>}
              />
            </TableCell>
            : ''}
          <TableCell className="py-2.5" style={{ fontSize: '0.7rem', padding: '0.4rem' }}>
            <NumberFormat
              value={Number(props.reserve.operational_cost_reserve) || 0}
              displayType={'text'}
              decimalSeparator={","}
              decimalScale={2}
              fixedDecimalScale={true}
              decimalPrecision={2}
              thousandSeparator={"."}
              isNumericString
              prefix={`${process.env.REACT_APP_CURRENCY} `}
              renderText={(value) => <span className="flex items-center">{value}</span>}
            />
          </TableCell>
          <TableCell className="py-2.5" style={{ fontSize: '0.7rem', padding: '0.4rem' }}>
            {props.reserve.type === 'tour' ?
              props.type === 'seller' ?
                <span>{props.reserve.status !== 'canceled' && props.reserve.percentage_commission_region ? props.reserve.percentage_commission_region : props.reserve.max_percentage_commission || 0}%</span>
                :
                <span>{props.reserve.status !== 'canceled' && props.reserve.percentage_commission || 0}%</span>
              :
              props.type === 'seller' ?
                <span>{props.reserve.status !== 'canceled' && props.reserve.percentage_commission_region || 0}</span>
                :
                <span>{props.reserve.status !== 'canceled' && props.reserve.percentage_commission || 0}</span>
            }
          </TableCell>
        </TableRow>
      }

      {props.print && props.travelType === 'tour' && (props.reserve.company_id == props.company || props.company == 'all' || props.company == undefined) &&
        <TableRow key={props.reserve.id}>
          <TableCell className="py-2.5" style={{ fontSize: '0.7rem', padding: '0.4rem' }}>
            {props.reserve.references}
          </TableCell>
          <TableCell className="py-2.5" style={{ fontSize: '0.7rem', padding: '0.4rem' }}>
            {props.reserve.company_name}
          </TableCell>
          <TableCell className="py-2.5" width="10%" style={{ fontSize: '0.7rem', padding: '0.4rem' }}>
            {props.reserve.customer_name}
          </TableCell>
          <TableCell className="py-2.5" width="10%" style={{ fontSize: '0.7rem', padding: '0.4rem' }}>
            {props.reserve.travel_name} - {props.reserve.transfer_type}
          </TableCell>
          <TableCell className="py-2.5" style={{ fontSize: '0.7rem', padding: '0.4rem' }}>
            {props.reserve.payment_status === 'pending' || props.reserve.payment_status === 'partial' ?
              <>
                Pendente
              </>
              : props.reserve.payment_status === 'accomplished' ?
                <>
                  Realizado
                </>
                : ''}
          </TableCell>
          <TableCell className="py-2.5" style={{ fontSize: '0.7rem', padding: '0.4rem' }}>
            {<Moment format="DD/MM/YYYY" date={props.reserve.tour_date} />}
          </TableCell>
          <TableCell className="py-2.5" style={{ fontSize: '0.7rem', padding: '0.4rem' }}>
            {props.type === 'seller' ?
              <NumberFormat
                //value={props.reserve.status !== 'canceled' ? Number((showFreeValue(props.reserve.reservation_brute_value) - totalOperationalCostTourView) - ((showFreeValue(props.reserve.reservation_brute_value) - totalOperationalCostTourView) * partnerComission / 100)) : 0 || 0}
                value={props.reserve.status !== 'canceled' ? Number(showFreeValue(props.reserve.reservation_brute_value) - totalOperationalCostTourView) : 0 || 0}
                displayType={'text'}
                decimalSeparator={","}
                decimalScale={2}
                fixedDecimalScale={true}
                decimalPrecision={2}
                thousandSeparator={"."}
                isNumericString
                prefix={`${process.env.REACT_APP_CURRENCY} `}
                renderText={(value) => <span className="flex items-center">{value}</span>}
              /> :
              <NumberFormat
                value={props.reserve.status !== 'canceled' ? Number((props.reserve.partner_commission_type === true ? showFreeValue(props.reserve.reservation_brute_value) - props.reserve.operational_cost_total : showFreeValue(props.reserve.reservation_brute_value))) : 0 || 0}
                displayType={'text'}
                decimalSeparator={","}
                decimalScale={2}
                fixedDecimalScale={true}
                decimalPrecision={2}
                thousandSeparator={"."}
                isNumericString
                prefix={`${process.env.REACT_APP_CURRENCY} `}
                renderText={(value) => <span className="flex items-center">{value}</span>}
              />
            }
          </TableCell>
          {props.travelType !== 'transfer' ? <>
            <TableCell className="py-2.5" style={{ fontSize: '0.7rem', padding: '0.4rem' }}>
              {props.reserve.type === 'tour' ?
                props.type === 'seller' ?
                  <NumberFormat
                    //value={props.reserve.status !== 'canceled' ? Number(((showFreeValue(props.reserve.reservation_brute_value) - totalOperationalCostTourView) - ((showFreeValue(props.reserve.reservation_brute_value) - totalOperationalCostTourView) * partnerComission / 100)) * (props.reserve.percentage_commission_region ? props.reserve.percentage_commission_region : props.reserve.max_percentage_commission) / 100) : 0 || 0}
                    value={props.reserve.status !== 'canceled' ? Number(((showFreeValue(props.reserve.reservation_brute_value) - totalOperationalCostTourView)) * (props.reserve.percentage_commission_region ? props.reserve.percentage_commission_region : props.reserve.max_percentage_commission) / 100) : 0 || 0}
                    displayType={'text'}
                    decimalSeparator={","}
                    decimalScale={2}
                    fixedDecimalScale={true}
                    decimalPrecision={2}
                    thousandSeparator={"."}
                    isNumericString
                    prefix={`${process.env.REACT_APP_CURRENCY} `}
                    renderText={(value) => <span className="flex items-center">{value}</span>}
                  />
                  :
                  <NumberFormat
                    value={props.reserve.status !== 'canceled' ? Number(props.reserve.partner_commission_type === true ? (showFreeValue(props.reserve.reservation_brute_value) - props.reserve.operational_cost_total) * (props.reserve.percentage_commission / 100) : (showFreeValue(props.reserve.reservation_brute_value)) * (props.reserve.percentage_commission / 100)) : 0 || 0}
                    displayType={'text'}
                    decimalSeparator={","}
                    decimalScale={2}
                    fixedDecimalScale={true}
                    decimalPrecision={2}
                    thousandSeparator={"."}
                    isNumericString
                    prefix={`${process.env.REACT_APP_CURRENCY} `}
                    renderText={(value) => <span className="flex items-center">{value}</span>}
                  />
                :
                props.type === 'seller' ?
                  <NumberFormat
                    value={Number(props.reserve.percentage_commission_region) || 0}
                    displayType={'text'}
                    decimalSeparator={","}
                    decimalScale={2}
                    fixedDecimalScale={true}
                    decimalPrecision={2}
                    thousandSeparator={"."}
                    isNumericString
                    prefix={`${process.env.REACT_APP_CURRENCY} `}
                    renderText={(value) => <span className="flex items-center">{value}</span>}
                  />
                  :
                  <NumberFormat
                    value={Number(props.reserve.percentage_commission) || 0}
                    displayType={'text'}
                    decimalSeparator={","}
                    decimalScale={2}
                    fixedDecimalScale={true}
                    decimalPrecision={2}
                    thousandSeparator={"."}
                    isNumericString
                    prefix={`${process.env.REACT_APP_CURRENCY} `}
                    renderText={(value) => <span className="flex items-center">{value}</span>}
                  />
              }
            </TableCell>
          </> : ''}
          {props.type === 'seller' ?
            <TableCell className="py-2.5" style={{ fontSize: '0.7rem', padding: '0.4rem' }}>
              {status[props.reserve.status] || 'Pendente'}
            </TableCell>
            : ''}
          {props.type === 'seller' ?
            <TableCell className="py-2.5" style={{ fontSize: '0.7rem', padding: '0.4rem' }}>
              <NumberFormat
                value={Number(showFreeValue(props.reserve.reservation_brute_value)) || 0}
                displayType={'text'}
                decimalSeparator={","}
                decimalScale={2}
                fixedDecimalScale={true}
                decimalPrecision={2}
                thousandSeparator={"."}
                isNumericString
                prefix={`${process.env.REACT_APP_CURRENCY} `}
                renderText={(value) => <span className="flex items-center">{value}</span>}
              />
            </TableCell>
            : ''}
          {props.reserve.type === 'tour' && props.type === 'seller' ?
            <TableCell className="py-2.5" style={{ fontSize: '0.7rem', padding: '0.4rem' }}>
              <NumberFormat
                //value={Number((showFreeValue(props.reserve.reservation_brute_value) - totalOperationalCostTourView) * partnerComission / 100) || 0}
                value={totalOperationalCostPartner}
                displayType={'text'}
                decimalSeparator={","}
                decimalScale={2}
                fixedDecimalScale={true}
                decimalPrecision={2}
                thousandSeparator={"."}
                isNumericString
                prefix={`${process.env.REACT_APP_CURRENCY} `}
                renderText={(value) => <span className="flex items-center">{value}</span>}
              />
            </TableCell>
            : ''}
          {props.type === 'seller' ?
            <TableCell className="py-2.5" style={{ fontSize: '0.7rem', padding: '0.4rem' }}>
              <NumberFormat
                value={Number(totalOperationalCostTourView) || 0}
                displayType={'text'}
                decimalSeparator={","}
                decimalScale={2}
                fixedDecimalScale={true}
                decimalPrecision={2}
                thousandSeparator={"."}
                isNumericString
                prefix={`${process.env.REACT_APP_CURRENCY} `}
                renderText={(value) => <span className="flex items-center">{value}</span>}
              />
            </TableCell>
            : ''}
          <TableCell className="py-2.5" style={{ fontSize: '0.7rem', padding: '0.4rem' }}>
            {props.reserve.type === 'tour' ?
              props.type === 'seller' ?
                <span>{props.reserve.percentage_commission_region ? props.reserve.percentage_commission_region : props.reserve.max_percentage_commission || 0}%</span>
                :
                <span>{props.reserve.percentage_commission || 0}%</span>
              :
              props.type === 'seller' ?
                <span>{props.reserve.percentage_commission_region || 0}</span>
                :
                <span>{props.reserve.percentage_commission || 0}</span>
            }
          </TableCell>
        </TableRow>
      }
    </>
  );
}

const SalesReserveTable = (props) => {
  const [result, setResult] = useState({})
  const [loading, setLoading] = useState(true)
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(15);
  const [status, setStatus] = useState(null);
  const [openModal, setOpenModal] = useState(false)
  const [selectedsIds, setSelectedsIds] = useState([]);
  const [allSelecteds, setAllSelecteds] = useState(false);
  const [totalFreeValue, setTotalFreeValue] = useState(0)
  const [totalCommission, setTotalCommission] = useState(0)
  const [tours, setTours] = useState([])

  let params = props.params

  useEffect(() => {
    //getTours()
  }, [])

  const getTours = async () => {
    const response = await apiClient.httpInstance.get(`/tours?pageSize=500`)

    if (response.status === 200) {
      return response.data.data
    }
  }

  const showFreeValue = (value, element, tours_) => {
    if (element?.region_id != '81' && element?.region_id != '79') {
      return value
    }
    const tour_date = new Date(element?.tour_date)
    const today = new Date()
    if ((tour_date < today) && !(tour_date > new Date('2024-02-29') && tour_date < new Date('2024-04-01')) && element?.region_id != '79' ) {
      return value
    } else {
      //const selectedTour = tours && tours?.find(tour => tour?.name?.match(element?.travel_name))
      const selectedTour = tours_ && tours_?.find(tour => tour?.name == element?.travel_name)

      if (!selectedTour) return value

      return Number(selectedTour?.price) * (Number(element?.adults_total) + Number(element?.kids_total))
    }
  }

  const handleChangePage = (event, newPage) => {
    getReserveBySeller(props.path, newPage, pageSize, params)
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPageSize(event.target.value);
    getReserveBySeller(props.path, page, event.target.value, params)
  };

  const getReserveBySeller = async (path, page, pageSize, params) => {
    let totalFree = 0
    let totalCommission = 0

    const tours_ = await getTours()

    apiClient.httpInstance.get(path, {
      params: {
        ...params,
        //page: page + 1,
        pageSize: 10000 //pageSize
      }
    }).then(response => {
      if (response.status === 200) {
        const data = response.data.data.map(obj => ({
          ...obj,
          select: false
        }))
        response.data.data = data
        for (let i = 0; i < response.data.data.length; i++) {
          var element = response.data.data[i];
          //data.forEach(element => {

          const brute_value = showFreeValue(element.reservation_brute_value, element, tours_)

          if (element.status !== 'canceled') {
            if (props.type === 'seller') {
              let totalOperationalCost = 0;
              let partnerComission = element.partner_seller_id && element.partner_seller_type ? element.percentage_commission || 0 : element.percentage_commission_partner || 0
              let totalOperationalCostPartner = (brute_value - parseFloat(element.operational_cost_total || 0)) * partnerComission / 100 || 0

              //Novo cálculo
              if (element.tour_date < '2023-09-15') {
                //Comissões anteriores a 15/09/2023
                if (element.partner_seller_id && element.partner_seller_type) {
                  totalOperationalCost = parseInt(element.operational_cost_total)
                } else if (element.partner_seller_id) {
                  if (parseInt(element.operational_cost_total_sale) > 0) {
                    totalOperationalCost = parseFloat(element.operational_cost_total_sale)
                  } else {
                    totalOperationalCost = parseFloat(element.operational_cost_total_sale) + parseFloat(element.operational_cost_total)
                  }
                } else {
                  totalOperationalCost = parseFloat(element.operational_cost_total_sale) + parseFloat(element.operational_cost_total)
                }
              } else {
                //Comissões posteriores a 15/09/2023, novo cálculo com separação do valor de venda e de parceiro em cada passeio
                if (element.partner_seller_id && element.partner_seller_type) {
                  totalOperationalCost = parseInt(element.operational_cost_total || 0)
                } else if (element.partner_seller_id) {
                  if (parseInt(element.operational_cost_total_sale) > 0) {
                    totalOperationalCost = parseFloat(element.operational_cost_total_sale) + totalOperationalCostPartner
                  } else {
                    totalOperationalCost = parseFloat(element.operational_cost_total)
                  }
                } else {
                  totalOperationalCost = parseFloat(element.operational_cost_total_sale)
                }
              }

              if (element.type === 'tour') {
                totalFree += parseFloat(brute_value) - totalOperationalCost
              } else if (element.type === 'transfer') {
                totalFree += parseFloat(brute_value) - parseFloat(element.operational_cost_reserve)
              }
              //Se existir parceiro, subtrai do valor livre
              if (element.partner_seller_id /* && element.partner_seller_type */) {
                if (element.type === 'tour') {
                  totalFree -= (brute_value - totalOperationalCost) * element.percentage_commission_partner / 100
                }
              }

              if (element.type === 'tour') {
                if (element.percentage_commission_region && element.percentage_commission_region !== null) {
                  let totalOperationalCostCalc = element.partner_seller_id && element.partner_seller_type ? parseFloat(element.operational_cost_total) : element.partner_seller_id ? parseInt(element.operational_cost_total_sale) > 0 ? parseFloat(element.operational_cost_total_sale) : parseFloat(element.operational_cost_total_sale) + parseFloat(element.operational_cost_total) : parseFloat(element.operational_cost_total)
                  let partnerComissionCalc = element.partner_seller_id && element.partner_seller_type ? element.percentage_commission : element.percentage_commission_partner
                  //value={props.reserve.status !== 'canceled' ? Number(((showFreeValue(props.reserve.reservation_brute_value) - totalOperationalCostTourView)) * (props.reserve.percentage_commission_region ? props.reserve.percentage_commission_region : props.reserve.max_percentage_commission) / 100) : 0 || 0}
                  //console.log(totalCommission, partnerComissionCalc, totalOperationalCost,Number(((brute_value - totalOperationalCost) - ((brute_value - totalOperationalCost) * partnerComissionCalc / 100)) * (element.percentage_commission_region ? element.percentage_commission_region : element.max_percentage_commission) / 100))
                  totalCommission += Number(((brute_value - totalOperationalCost) - ((brute_value - totalOperationalCost) * (partnerComissionCalc || 0) / 100)) * (element.percentage_commission_region ? element.percentage_commission_region : element.max_percentage_commission) / 100) || 0
                } else if (element.max_percentage_commission && element.max_percentage_commission !== null) {

                  let totalOperationalCostCalc = element.partner_seller_id && element.partner_seller_type ? parseFloat(element.operational_cost_total) : element.partner_seller_id ? parseInt(element.operational_cost_total_sale) > 0 ? parseFloat(element.operational_cost_total_sale) : parseFloat(element.operational_cost_total_sale) + parseFloat(element.operational_cost_total) : parseFloat(element.operational_cost_total)
                  totalCommission += ((brute_value - totalOperationalCostCalc) * element.max_percentage_commission / 100)
                }
              } else if (element.type === 'transfer') {
                totalCommission += Number(element.percentage_commission_region)
              }
            } else {
              if (element.partner_commission_type === true) {
                totalFree += parseFloat(brute_value - element.operational_cost_total)
                if (element.type === 'tour') {
                  if (element.percentage_commission && element.percentage_commission !== null) {
                    totalCommission += ((brute_value - element.operational_cost_total) * element.percentage_commission / 100)
                  }
                } else if (element.type === 'transfer') {
                  totalCommission += Number(element.percentage_commission)
                }
              } else {
                totalFree += parseFloat(brute_value)
                if (element.type === 'tour') {
                  if (element.percentage_commission && element.percentage_commission !== null) {
                    totalCommission += ((brute_value) * element.percentage_commission / 100)
                  }
                } else if (element.type === 'transfer') {
                  totalCommission += Number(element.percentage_commission)
                }
              }
            }
          }
        }
        //});
        setTotalFreeValue(totalFree)
        setTotalCommission(totalCommission)

        setResult({
          ...response.data,
          checkAll: false
        });
        setLoading(false)
      }
    })
      .catch((error) => console.log(error));
  }

  const changeCheckbox = (value, all, idx) => {
    if (!all && value === true && !selectedsIds.includes(result.data[idx]?.id)) {
      setSelectedsIds([...selectedsIds, result.data[idx]?.id])
    }

    if (!all && value === false && selectedsIds.includes(result.data[idx]?.id)) {
      setSelectedsIds(selectedsIds.filter(function (element) {
        return element !== result.data[idx]?.id
      }))
    }

    if (all) {
      if (value === false) {
        setSelectedsIds([])
      }
      const data = result.data.map(obj => ({
        ...obj,
        select: value
      }))
      data.forEach(element => {
        if (!selectedsIds.includes(element.id)) {
          selectedsIds.push(element.id)
        }
      });
      result.data = data
      setResult({
        ...result,
        checkAll: value
      })
    } else {
      let newArr = { ...result }
      newArr.data[idx]['select'] = value
      setResult(newArr)
    }
  }

  const handleUpdateStatus = () => {
    updateStatus(selectedsIds)
  }

  const updateStatus = async (ids) => {
    setLoading(true)
    var formData = new FormData;
    for (var i = 0; i < ids.length; i++) {
      formData.append('ids[]', ids[i]);
    }
    await apiClient.httpInstance.put(`/commissions/${props.type}/${props.id}/update_status`, {
      status,
      comissions_ids: ids
    }).then(response => {
      if (response.status === 200) {
        getReserveBySeller(props.path, page, pageSize, params);
        setLoading(false)
        setSelectedsIds([])
        setOpenModal(false)
      }
    })
      .catch((error) => {
        setLoading(false)
        console.log(error)
      });
  }

  const handleOpenModal = (reservation_id) => {
    setOpenModal(true)
  }

  useEffect(() => {
    if (props.get_data_reservations === true)
      getReserveBySeller(props.path, page, pageSize, params);
  }, [props.path, page, pageSize, params]);

  return <>
    <div className="flex flex-row-reverse my-7">
      <div className="row">
        Total livre: <NumberFormat
          value={Number(totalFreeValue) || 0}
          displayType={'text'}
          decimalSeparator={","}
          decimalScale={2}
          fixedDecimalScale={true}
          decimalPrecision={2}
          thousandSeparator={"."}
          isNumericString
          prefix={`${process.env.REACT_APP_CURRENCY} `}
          renderText={(value) => <span>{value}</span>}
        />            Total comissão: <NumberFormat
          value={Number(totalCommission) || 0}
          displayType={'text'}
          decimalSeparator={","}
          decimalScale={2}
          fixedDecimalScale={true}
          decimalPrecision={2}
          thousandSeparator={"."}
          isNumericString
          prefix={`${process.env.REACT_APP_CURRENCY} `}
          renderText={(value) => <span>{value}</span>}
        />
      </div>
      {props.month ?
        <div className="flex flex-row-reverse">
          <div className="row flex-end"><small><small>Total referente ao mês de {props.month}</small></small></div>
        </div>
        : ''}
    </div>

    <Paper style={props.print ? { width: '100%', overflow: 'hidden', boxShadow: 'none' } : { width: '100%', overflow: 'hidden' }}>
      <TableContainer>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            {!props.print &&
              <TableRow>
                <TableCell className="font-bold bg-white group">
                  {permissions.includes(props?.permission) && <Checkbox
                    className={!result.checkAll ? "hidden p-0 group-hover:block" : "p-0"}
                    value={result.checkAll}
                    checked={result.checkAll}
                    onChange={(ev) => changeCheckbox(ev.target.checked, true)}
                    color="dark"
                  />}
                  Referência
                </TableCell>
                <TableCell className="font-bold bg-white">
                  Empresa
                </TableCell>
                <TableCell className="font-bold bg-white">
                  Cliente
                </TableCell>
                <TableCell className="font-bold bg-white">
                  {params.travelType === 'tour' ? 'Passeio' : 'Traslado'}
                </TableCell>
                <TableCell className="font-bold bg-white">
                  Pagamento
                </TableCell>
                <TableCell className="font-bold bg-white">
                  Data do passeio
                </TableCell>
                {params.travelType !== 'transfer' ?
                  <TableCell className="font-bold bg-white">
                    Valor livre
                  </TableCell>
                  : ''}
                <TableCell className="font-bold bg-white">
                  Comissão
                </TableCell>
                <TableCell className="items-center justify-between font-bold bg-white">
                  Status da comissão
                  {
                    result.checkAll &&
                    <Icon
                      className="ml-2 cursor-pointer text-lightblue"
                      onClick={() => setOpenModal(true)}
                    >
                      editar
                    </Icon>
                  }
                </TableCell>
              </TableRow>
            }
            {props.print &&
              <TableRow>
                <TableCell className="font-bold bg-white" style={{ fontSize: '0.7rem', padding: '0.4rem' }}>
                  Referência
                </TableCell>
                <TableCell className="font-bold bg-white" style={{ fontSize: '0.7rem', padding: '0.4rem' }}>
                  Empresa
                </TableCell>
                <TableCell className="font-bold bg-white" style={{ fontSize: '0.7rem', padding: '0.4rem' }}>
                  Cliente
                </TableCell>
                <TableCell className="font-bold bg-white" style={{ fontSize: '0.7rem', padding: '0.4rem' }}>
                  {params.travelType === 'tour' ? 'Passeio' : 'Traslado'}
                </TableCell>
                <TableCell className="font-bold bg-white" style={{ fontSize: '0.7rem', padding: '0.4rem' }}>
                  Pagamento
                </TableCell>
                <TableCell className="font-bold bg-white" width="10%" style={{ fontSize: '0.7rem', padding: '0.4rem' }}>
                  Data do passeio
                </TableCell>
                {params.travelType !== 'transfer' ?
                  <TableCell className="font-bold bg-white" style={{ fontSize: '0.7rem', padding: '0.4rem' }}>
                    Valor livre
                  </TableCell>
                  : ''}
                <TableCell className="font-bold bg-white" style={{ fontSize: '0.7rem', padding: '0.4rem' }}>
                  Comissão
                </TableCell>
                {props.type === 'seller' ?
                  <TableCell className="font-bold bg-white" style={{ fontSize: '0.7rem', padding: '0.4rem' }}>
                    Status
                  </TableCell>
                  : ''}
                {props.type === 'seller' ?
                  <TableCell className="font-bold bg-white" style={{ fontSize: '0.7rem', padding: '0.4rem' }}>
                    Valor bruto
                  </TableCell>
                  : ''}
                {params.travelType !== 'transfer' && props.type === 'seller' ?
                  <TableCell className="font-bold bg-white" style={{ fontSize: '0.7rem', padding: '0.4rem' }}>
                    Parceiro
                  </TableCell>
                  : ''}
                {props.type === 'seller' ?
                  <TableCell className="font-bold bg-white" width="10%" style={{ fontSize: '0.7rem', padding: '0.4rem' }}>
                    Custo operacional
                  </TableCell>
                  : ''}
                <TableCell className="font-bold bg-white" style={{ fontSize: '0.7rem', padding: '0.4rem' }}>
                  {params.travelType !== 'transfer' ?
                    '% comissão'
                    :
                    'Comissão fixa'
                  }
                </TableCell>
              </TableRow>
            }
          </TableHead>
          <TableBody>
            {!loading &&
              <>
                {result.data.map((obj, idx) => {
                  return (
                    <TableBodyData
                      tours={tours}
                      checkAll={result.checkAll}
                      reserve={obj}
                      changeCheckbox={changeCheckbox}
                      idx={idx}
                      setOpenModal={handleOpenModal}
                      print={props.print}
                      type={props.type}
                      travelType={params.travelType}
                      permission={props.permission}
                    />
                  )
                }
                )}
              </>
            }
          </TableBody>
        </Table>
      </TableContainer>
      {/* {!props.print &&
        <TablePagination
          rowsPerPageOptions={[5, 10, 15]}
          component="div"
          count={result.total}
          rowsPerPage={pageSize}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      } */}
    </Paper>
    <ChangeStateModal
      open={openModal}
      loading={loading}
      close={() => setOpenModal(false)}
      onCreate={handleUpdateStatus}
      status={status}
      setStatus={setStatus}
      {...props}
    />
  </>
}

export default SalesReserveTable;

