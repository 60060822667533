import BreadCrumbs from '@components/Breadcrumbs';
import Button from '@components/Button';
import Icon from '@material-ui/core/Icon';
import Alert from '@material-ui/lab/Alert';
import { apiClient } from '@services/api';
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import Form from './Form';

const permissions = ['administrador'];

const Update = (props) => {
  document.title = `Editar passeio - ${process.env.REACT_APP_NAME}`
  const { tour_id } = useParams();
  const [tour, setTour] = useState({
    banner: null,
    name: "",
    region: "",
    tour_period: "",
    day_period: "",
    description: "",
    price: "",
    adult_discount: "",
    adult_discount_percentage: "",
    operational_cost: "",
    operational_cost_sale: "",
    kids_price: "",
    kid_discount: "",
    kid_discount_percentage: "",
    kid_operational_cost: "",
    kid_operational_cost_sale: "",
    min_quantity: "",
    id_site: "",
    private_values: [
      {
        value: "",
        cost: "",
        cost_sale: "",
        min_people: 1,
        max_people: 2,
        discount: ""
      }
    ]
  })
  const [oldBanner, setOldBanner] = useState(null)
  const [isEditing, setIsEditing] = useState(false)
  let url = `/tours/${tour_id}`;
  const history = useHistory();
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const bradecrumbsConfig = [
    {
      title: 'Início',
      active: true,
      route: '/admin/passeios'
    },
    {
      title: `${tour.name !== undefined ? tour.name : ''}`,
      active: true,
      route: `/admin/passeios/detalhe/${tour_id}`
    },
    {
      title: 'Edição',
    }
  ]
  const getTourById = url => {
    apiClient
      .httpInstance.get(url)
      .then(response => {
        if (response.status === 200) {
          const private_values = [{
            value: "",
            cost: "",
            cost_sale: "",
            min_people: 1,
            max_people: 2,
            discount: ""
          }]

          const tour = {
            banner: response.data.banner,
            name: response.data.name === null ? "" : response.data.name,
            region: response.data.region === null ? "" : response.data.region,
            tour_period: response.data.tour_period === null ? "" : response.data.tour_period,
            day_period: response.data.day_period === null ? "" : response.data.day_period,
            description: response.data.description === null ? "" : response.data.description,
            price: response.data.price === null ? "" : response.data.price,
            adult_discount: response.data.adult_discount === null ? "" : response.data.adult_discount,
            adult_discount_percentage: response.data.adult_discount_percentage === null ? "" : response.data.adult_discount_percentage,
            operational_cost: response.data.operational_cost === null ? "" : response.data.operational_cost,
            operational_cost_sale: response.data.operational_cost_sale === null ? "" : response.data.operational_cost_sale,
            kids_price: response.data.kids_price === null ? "" : response.data.kids_price,
            kid_discount: response.data.kid_discount === null ? "" : response.data.kid_discount,
            kid_discount_percentage: response.data.kid_discount_percentage === null ? "" : response.data.kid_discount_percentage,
            kid_operational_cost: response.data.kid_operational_cost === null ? "" : response.data.kid_operational_cost,
            kid_operational_cost_sale: response.data.kid_operational_cost_sale === null ? "" : response.data.kid_operational_cost_sale,
            private_values: response.data.privateValues.length > 0 ? response.data.privateValues : private_values,
            min_quantity: response.data.min_quantity?? 0,
            id_site: response.data.id_site?? 0
          }
          setTour(tour);
          setOldBanner(response.data.banner)
          setIsEditing(true)
        }
      }
      );
  }

  const changeState = (ev) => {
    setTour(
      {
        ...tour,
        [ev.target.name]: ev.target.value
      }
    )
  }
  const changeFile = (data) => {
    setTour(
      {
        ...tour,
        banner: data
      }
    )
  }

  const changeStatePrivate = (idx, ev) => {
    const value = ev.target.value
    const newArr = [...tour.private_values]
    newArr[idx][ev.target.name] = value
    setTour({
      ...tour,
      private_values: newArr
    })
  }

  const addPrivate = () => {
    const privateTour = tour.private_values
    const nextMinPeople = Number(privateTour[tour.private_values.length - 1].max_people) + 1
    const newPrivate = {
      value: "",
      cost: "",
      cost_sale: "",
      min_people: nextMinPeople,
      max_people: nextMinPeople + 1,
      discount: ""
    }
    privateTour.push(newPrivate)
    setTour({
      ...tour,
      private_values: privateTour
    })
  }

  const removePrivate = (idx) => {
    const privateTour = tour.private_values
    privateTour.splice(idx, 1);
    setTour({
      ...tour,
      private_values: privateTour
    })
  }

  const submitTourEdit = (ev) => {
    ev.preventDefault();
    const formData = new FormData()

    if (oldBanner !== tour.banner) {
      formData.append('banner', tour.banner)
    }

    formData.append('name', tour.name)
    formData.append('region', tour.region)
    formData.append('tour_period', tour.tour_period)
    formData.append('day_period', tour.tour_period === 'fullday' ? null : tour.day_period)
    formData.append('description', tour.description === null)
    formData.append('price', tour.price)
    formData.append('adult_discount', tour.price * tour.adult_discount_percentage / 100)
    formData.append('adult_discount_percentage', tour.adult_discount_percentage)
    formData.append('operational_cost', tour.operational_cost)
    formData.append('operational_cost_sale', tour.operational_cost_sale)
    formData.append('kids_price', tour.kids_price)
    formData.append('kid_discount', tour.kids_price * tour.kid_discount_percentage / 100)
    formData.append('kid_discount_percentage', tour.kid_discount_percentage)
    formData.append('kid_operational_cost', tour.kid_operational_cost)
    formData.append('kid_operational_cost_sale', tour.kid_operational_cost_sale)
    formData.append('min_quantity', tour.min_quantity?? 0)
    formData.append('id_site', tour.id_site?? 0)
    formData.append('company_id', props.user.data.company_id)
    formData.append('_method', 'PUT')
    apiClient
      .httpInstance.post(`/tours/${tour_id}`,
        formData,
        { headers: { "Content-Type": "multipart/form-data" } }
      )
      .then(response => {
        if (response.status === 200 || response.status === 201) {
          if (tour.private_values.length > 0) {
            submitPrivate(response.data.id, tour.private_values)
          } else {
            history.push('/admin/passeios');
          }
        }
      })
      .catch(error => {
        if (error) {
          setError(true);
          setErrorMessage(error.response.message || "Erro inesperado, tente novamente mais tarde")
          setTimeout(() => { setError(false) }, 4000);
        }
      });
  }

  const submitPrivate = (id, privateValues) => {
    apiClient
      .httpInstance.post(`/tours/${id}/sync_private_values`, {
        private_values: privateValues
      })
      .then(response => {
        if (response.status === 200 || response.status === 201) {
          history.push('/admin/passeios');
        }
      })
      .catch(error => {
        if (error) {
          setError(true);
          setErrorMessage(error.response.message || "Erro inesperado, tente novamente mais tarde")
          setTimeout(() => { setError(false) }, 4000);
        }
      });
  }

  useEffect(() => {
    getTourById(url);
  }, [url]);
  if (isEditing) {
    return <>
      <BreadCrumbs items={bradecrumbsConfig} />
      {error && (
        <Alert className="mb-5" variant="filled" severity="error">
          {errorMessage}
        </Alert>
      )}
      {permissions.includes(props?.theme?.permission) ?
        <div className="px-7 pt-6">
          <div className="flex items-center justify-between mt-2 text-2xl text-blue">
            <span>Edição</span>
            <Button
              textcolor="#212121"
              padding="6px 15px"
              width="200"
              variant="outlined"
              bgColor="transparent"
            >
              <Icon className="mr-2">
                arrow_back
              </Icon>
              Cancelar
            </Button>
          </div>
          <Form
            {...props}
            submit={submitTourEdit}
            tour={tour}
            isEditing={isEditing}
            changeState={changeState}
            changeFile={changeFile}
            changeStatePrivate={changeStatePrivate}
            addPrivate={addPrivate}
            removePrivate={removePrivate}
          />
        </div>
        :
        <div className="px-7 pt-6">
          <div className="flex items-center justify-between mt-2 text-2xl text-blue">
            <span>Edição</span>
            <Button
              textcolor="#212121"
              padding="6px 15px"
              width="200"
              variant="outlined"
              bgColor="transparent"
            >
              <Icon className="mr-2">
                arrow_back
              </Icon>
              Cancelar
            </Button>
          </div>
          <div className="mt-4 bg-white text-blue font-poppins" style={{ borderRadius: '10px' }}>
            Você não possui permissão para acessar este recurso.
          </div>
        </div>
      }
    </>
  } else {
    return <>
    </>
  }
}

const mapStateToProps = state => {
  return {
    user: state.user
  }
}

export default connect(
  mapStateToProps
)(Update);
