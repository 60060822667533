import TextField from '@components/TextField'
import Button from '@components/Button'
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import Radio from '@material-ui/core/Radio';
import { withStyles } from '@material-ui/core/styles';
import { useHistory } from "react-router-dom";

const GreenRadio = withStyles({
  root: {
    color: `${process.env.REACT_APP_SELLER_COLOR}`,
    '&$checked': {
      color: `${process.env.REACT_APP_SELLER_COLOR}`,
    },
  },
  checked: {},
})((props) => <Radio color="default" {...props} />);

const FormService = (props) => {
  const history = useHistory();

  return <>
    <form onSubmit={props.submit}>
      <div className="p-4 mt-4 bg-white rounded-xl border-1">
        <div className="flex mb-4 space-x-3">
          <div className="flex-1">
            <TextField
              className="inputRounded"
              label="Empresa"
              variant="outlined"
              type="text"
              name="titulo"
              value={props.service?.titulo}
              onChange={(ev) => props.changeState(ev)}
              required
            />
          </div>
          <div className="flex-1">
            <TextField
              className="inputRounded"
              label="Nome do serviço"
              variant="outlined"
              type="text"
              name="name"
              value={props.service?.name}
              onChange={(ev) => props.changeState(ev)}
              required
            />
          </div>
          <div className="flex-1">
            <TextField
              className="inputRounded"
              label="Token"
              variant="outlined"
              type="text"
              name="access_token"
              value={props.service?.access_token}
              onChange={(ev) => props.changeState(ev)}
              required
            />
          </div>
        </div>
        <div className="flex mb-4 space-x-3">
          <div className="flex-1">
            <label className="text-dark">Enviar nota manualmente</label>
            <div className="flex-col d-flex text-gray">
              <FormControl component="fieldset">
                <RadioGroup row aria-label="manual_action" name="manual_action" value={props.service?.manual_action + '' || 'false'} onChange={(e) => props.changeState(e)}>
                  <FormControlLabel value="true" control={<GreenRadio />} label="Sim" />
                  <FormControlLabel value="false" control={<GreenRadio />} label="Não" />
                </RadioGroup>
              </FormControl>
            </div>
          </div>
        </div>
        <div className="flex mb-4 space-x-3">
          <div className="flex-1">
            <label className="text-dark">Enviar e-mail ao cliente</label>
            <div className="flex-col d-flex text-gray">
              <FormControl component="fieldset">
                <RadioGroup row aria-label="send_feedback_to_client" name="send_feedback_to_client" value={props.service?.send_feedback_to_client + '' || 'false'} onChange={(e) => props.changeState(e)}>
                  <FormControlLabel value="true" control={<GreenRadio />} label="Sim" />
                  <FormControlLabel value="false" control={<GreenRadio />} label="Não" />
                </RadioGroup>
              </FormControl>
            </div>
          </div>
        </div>
      </div>
      <div className="text-right space-x-3 mb-4">
        <Button
          textcolor="#212121"
          padding="6px 15px"
          width="200"
          variant="outlined"
          onClick={() => history.push('/admin/configuracoes')}
          bgColor="transparent"
        >
          Cancelar
        </Button>
        <Button
          textcolor="#FFF"
          padding="6px 15px"
          width="200"
          customcolor={props.theme.color}
          type="submit"
        >
          Salvar
        </Button>
      </div>
    </form>
  </>
}

export default FormService
