import { useState, useRef } from 'react'
import Modal from '@components/Modal';
import Button from '@components/Button';
import Icon from '@material-ui/core/Icon';
import TextField from '@components/TextField';
import Alert from '@material-ui/lab/Alert';
import {apiClient} from '@services/api';
import CircularProgress from '@material-ui/core/CircularProgress';
import RadioButton from '@components/RadioButton';

const SendVoucherModal = (props) => {
  const [email, setEmail] = useState('');
  const [typeVoucher, setTypeVoucher] = useState('confirm');
  const [sendingVoucher, setSendingVoucher] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  let ref = useRef(null);
  const sendVoucherUrl = `sales/${props.saleId}/send_voucher`;

  const radioButtonData = [
    {
      value: 'confirm',
      label: 'Confirmação'
    },
    {
      value: 'cancel',
      label: 'Cancelamento'
    }
  ];

  const sendVoucher = (e) => {
    e.preventDefault();

    if (email) {
      setSendingVoucher(true);
      apiClient.httpInstance.post(sendVoucherUrl, {
        "email": email, "type": typeVoucher,  "resend": 'yes'
      }).then(response => {
        if (response.status === 200) {
          setSuccess(true);
          setSendingVoucher(false);
          setTimeout(() => {
            setSuccess(false);
          }, 2000);
        }
      });
    } else {
      setError(true);
      setTimeout(() => {
        setError(false);
      }, 2000);
    }
  }

  return <Modal open={props.open} close={props.close} style={{ width: '85%', maxWidth: '550px'}}>
    <div className="flex justify-between font-poppins">
      <div className="flex">
        <div className="flex flex-col">
          <span className="mb-3 text-xl text-blue">Reenviar voucher</span>
        </div>
      </div>
      <Icon ref={ref} className="cursor-pointer" onClick={props.close}>close</Icon>
    </div>
    <form onSubmit={sendVoucher}>
      <div className="flex flex-col justify-center mt-4">
        {error && <Alert className="mb-3" severity="error" color="error">
          Email não informado
        </Alert>}
        {success && <Alert className="mb-3" severity="success" color="success">
          Voucher enviado com sucesso
        </Alert>}
        <TextField
          className="inputRounded"
          label="E-mail"
          variant="outlined"
          type="email"
          name="email"
          value={email}
          onChange={(ev) => setEmail(ev.target.value)}
          required
        />
      </div>
      <div className="p-4 text-xl text-blue font-poppins flex">
      <div className="flex-1 space-y-1">
        <RadioButton
          {...props}
          data={radioButtonData}
          name="typeVoucher"
          change={(ev) => setTypeVoucher(ev.target.value)}
          value={typeVoucher}
        />
        <span 
          style={{ fontSize: '10px' }}
          id="labelRadioVoucher">Selecione o tipo de Voucher
        </span>
      </div>
    </div>
      <div className="justify-center mt-4 space-x-3 md:justify-end md:flex w-100">
        <Button
          textcolor="#212121"
          padding="10px 15px"
          margintop="0px"
          width="120px"
          variant="outlined"
          onClick={props.close}
          bgColor="transparent"
        >
            Cancelar
        </Button>
        <Button
          textcolor="#fff"
          padding="10px 15px"
          margintop="0px"
          width="150px"
          type="submit"
          disabled={sendingVoucher}
          customcolor={props.theme.color}
        >
            Reenviar <span class="ml-2"><CircularProgress style={{ display: sendingVoucher ? 'block' : 'none' }} size={15} /></span>
        </Button>
      </div>
    </form>
  </Modal>
}

export default SendVoucherModal;
