import BreadCrumbs from '@components/Breadcrumbs';
import Button from '@components/Button';
import Icon from '@material-ui/core/Icon';
import Form from './Form';
import Alert from '@material-ui/lab/Alert';
import { Link, useHistory, useParams } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { apiClient } from '@services/api';

const permissions = ['administrador'];

const breadcrumbsConfig = (props) => ([
  {
    title: 'Início',
    active: true,
    route: '/admin/parceiros/agencias'
  },
  {
    title: `${props.name || ''}`,
    active: true,
    route: `/admin/parceiros/agencias/detalhe/${props.id || ''}`
  },
  {
    title: 'Edição',
  }
]);

const Update = (props) => {
  document.title = `Editar agência parceira - ${process.env.REACT_APP_NAME}`
  const { agency_id } = useParams();
  const [error] = useState(false);
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(true);
  const [errors, setErrors] = useState({});
  const [errorMessage] = useState("");
  const history = useHistory();
  const [agency, setAgency] = useState({
    name: '',
    social_reason: '',
    contact_person: '',
    telephone: '',
    email: '',
    observations: '',
    percentage: 0,
    operational_cost: 'true',
  });
  const url = `/admin/parceiros/agencias`;
  const resourcePath = `/agencies/${agency_id}`;

  const getAgencyData = resourcePath => {
    apiClient.httpInstance.get(resourcePath).then(response => {
      if (response.status === 200) {
        setAgency(response.data);

        if (response.data.percentage < 0 || response.data.percentage == null) {
          setAgency({
            ...response.data,
            percentage: 0
          });
        }
        setLoading(false);
      }
    })
      .catch((error) => console.log(error));
  }

  useEffect(() => {
    getAgencyData(resourcePath);
  }, [resourcePath]);

  const changeState = (e) => {
    setAgency(
      {
        ...agency,
        [e.target.name]: e.target.value
      }
    )
  }

  const handleSubmit = (e) => {
    e.preventDefault();

    console.log(resourcePath);
    apiClient.httpInstance.put(resourcePath + '/updateAgency', {
      ...agency
    })
      .then(response => {
        if (response.status === 200 || response.status === 201) {
          setSuccess(true);
          setTimeout(() => {
            setSuccess(false);
            history.push(url);
          }, 2000);
        }
      })
      .catch(error => {
        if (error) {
          setErrors(error.response.data);
          setTimeout(() => { setErrors("") }, 4000);
        }
      });
  }

  return <>
    <BreadCrumbs items={breadcrumbsConfig(agency)} />
    {error && (
      <Alert className="mb-5" variant="filled" severity="error">
        {errorMessage}
      </Alert>
    )}
    {!loading &&
      permissions.includes(props?.theme?.permission) ?
      <div className="px-7 pt-6">
        <div className="flex items-center justify-between mt-2 text-2xl text-blue">
          <span>Edição</span>
          <Link color="inherit" to={`/admin/parceiros/agencias/detalhe/${agency.id || ''}`}>
            <Button
              textcolor="#212121"
              padding="6px 15px"
              width="200"
              variant="outlined"
              bgColor="transparent"
            >
              <Icon className="mr-2">
                arrow_back
              </Icon>
              Cancelar
            </Button>
          </Link>
        </div>
        <div className="flex flex-wrap items-center justify-between">
          {success && <Alert className="mt-5" severity="success" color="success">
            Agência atualizada com sucesso
          </Alert>}
        </div>
        <Form
          {...props}
          submit={handleSubmit}
          errors={errors}
          agency={agency}
          isEditing={true}
          changeState={changeState} />
      </div>
      :
      <div className="px-7 pt-6">
        <div className="flex items-center justify-between mt-2 text-2xl text-blue">
          <span>Edição</span>
          <Link color="inherit" to={`/admin/parceiros/agencias/detalhe/${agency.id || ''}`}>
            <Button
              textcolor="#212121"
              padding="6px 15px"
              width="200"
              variant="outlined"
              bgColor="transparent"
            >
              <Icon className="mr-2">
                arrow_back
              </Icon>
              Cancelar
            </Button>
          </Link>
        </div>
        <div className="flex flex-wrap items-center justify-between">
          <div className="mt-4 bg-white text-blue font-poppins" style={{ borderRadius: '10px' }}>
            Você não possui permissão para acessar este recurso.
          </div>
        </div>
      </div>
    }
  </>
}

export default Update;
