import Button from '@components/Button';
import Icon from '@material-ui/core/Icon';
import { useParams, useLocation } from 'react-router-dom';
import { apiClient } from '@services/api';
import { useState, useEffect } from 'react';
import { useHistory } from "react-router-dom";
import BreadCrumbs from '@components/Breadcrumbs'
import DatePicker from '@components/DatePicker';
import ContentStep1 from './steps/ContentStep1'
import ContentStep2 from './steps/ContentStep2'
import { format, isValid } from 'date-fns';
import Alert from '@material-ui/lab/Alert';
import FinishBuy from '../FinishBuy'

const BuyTour = (props) => {
  document.title = `Comprar passeio - ${process.env.REACT_APP_NAME}`
  const location = useLocation()
  const quantityList = location.state?.cupos !== undefined ? location.state.cupos : 0
  const { tour_id } = useParams();
  const [tour, setTour] = useState({});
  const [dateAvailable, setDateAvailable] = useState([])
  const [step, setStep] = useState(1)
  const [loading, setLoading] = useState(false);
  const [reservationTour, setReservationTour] = useState({
    quantityAdult: 0,
    quantityKid: 0,
    date: null,
    typeTour: 'Em grupo',
    discountAdult: 0,
    discountKid: 0,
    totalAdult: 0,
    totalKid: 0,
    total: 0
  })
  const [error, setError] = useState(false)
  const [errorMessage, setErrorMessage] = useState(null)
  const history = useHistory();
  const url = `/tours/${tour_id}`
  const urlDatesUnvaliable = `reservations_dates/available?tour_id=${tour_id}`

  const getToursById = (url) => {
    apiClient.httpInstance.get(url).then(response => {
      if (response.status === 200) {
        setTour({
          ...response.data,
          availables_cupos: 0
        });
        console.log('response.data', response.data)
        setLoading(true)
      }
    })
      .catch((error) => console.log(error));
  }

  const getDateAvailable = url => {
    apiClient.httpInstance.get(url).then(response => {
      if (response.status === 200) {
        setDateAvailable(response.data);
      }
    })
      .catch((error) => console.log(error));
  }

  const bradecrumbsConfig = [
    {
      title: 'Início',
      active: true,
      route: '/admin/reservas'
    },
    {
      title: 'Passeio',
      active: true,
      route: '/admin/reservas/passeios'
    },
    {
      title: `Comprar`,
    }
  ]

  const disableDates = (date) => {
    let dates = null

    if (dateAvailable.length > 0) {
      dateAvailable.map((dateT, index) => {
        if (index === 0) {
          dates = date.toDateString() === new Date(`${dateT.cupo_date}T00:00:00`).toDateString() && dateT.total_cupos > 0;
        } else {
          dates += date.toDateString() === new Date(`${dateT.cupo_date}T00:00:00`).toDateString() && dateT.total_cupos > 0;
        }
      })
    } else {
      dates = false;
    }
    return dates
  }

  const setQuantities = (value, type, math) => {
    let valueQuantity = math === 'add' ? (Number(value) + 1) : (Number(value) - 1)
    let totalName = null
    let totalValue = 0

    if (type === 'quantityAdult') {
      totalName = 'totalAdult'
      totalValue = (Number(tour.price) * Number(valueQuantity))
    } else {
      totalName = 'totalKid'
      totalValue = (Number(tour.kids_price) * Number(valueQuantity))
    }


    setReservationTour({
      ...reservationTour,
      [type]: valueQuantity,
      [totalName]: totalValue
    })
  }

  const changeValue = (ev) => {
    const value = ev.target.value

    setReservationTour({
      ...reservationTour,
      [ev.target.name]: value
    })
  }

  const changeDate = (date) => {
    const value = isValid(date) === true ? format(date, "yyyy-MM-dd") : null
    setReservationTour({
      ...reservationTour,
      date: value
    })
    getDate(value)
  }

  const addCart = (type) => {
    const subTotal = Number(reservationTour.totalAdult) + Number(reservationTour.totalKid)
    const valueAdult = (Number(reservationTour.totalAdult) * (Number(reservationTour.discountAdult) / 100))
    const valueKid = (Number(reservationTour.totalKid) * (Number(reservationTour.discountKid) / 100))
    const discountTotal = (valueAdult + valueKid)

    apiClient
      .httpInstance.post("/user/cart", {
        tour_id: tour.id,
        travel_id: tour.id,
        travel_type: 'tour',
        tour_date: reservationTour.date,
        operational_cost: reservationTour.typeTour === "Em grupo" ? (reservationTour.quantityAdult * reservationTour.operational_cost) + (reservationTour.quantityKid * reservationTour.kid_operational_cost) : 0,
        adults_total: reservationTour.quantityAdult,
        adults_total_amount: reservationTour.totalAdult,
        adults_operational_cost: tour.operational_cost,
        adults_operational_cost_sale: tour.operational_cost_sale,
        adults_discount: reservationTour.discountAdult,
        kids_total: reservationTour.quantityKid,
        kids_total_amount: reservationTour.totalKid,
        kids_operational_cost: tour.kid_operational_cost,
        kids_operational_cost_sale: tour.kid_operational_cost_sale,
        kids_discount: reservationTour.discountKid,
        value_dolars_cost: reservationTour.value_dolars_cost,
        discount_total_amount: discountTotal,
        total_amount: subTotal - (valueAdult + valueKid),
        cupos: reservationTour.quantityAdult + reservationTour.quantityKid,
        is_group: reservationTour.typeTour === "Em grupo" ? true : false
      })
      .then(response => {
        if (response.status === 200 || response.status === 201) {
          //Change counter Cart
          let url = '/user/cart';
            apiClient.httpInstance.get(url).then(response => {
              if (response.status === 200) {
                let counter = response.data.length
                if (counter && counter > 0) {
                  document.getElementById("countItensCart").innerHTML = counter < 10 ? '0' + counter : counter
                  document.getElementById("countItensCart").classList.remove("hidden")
                } else {
                  document.getElementById("countItensCart").classList.add("hidden")
                }
              }
            })
              .catch((error) => console.log(error));
              
          if (type === 'cart') {
            history.push('/admin/reservas/passeios');
          } else {
            setStep(3)
          }
        }
      })
      .catch(error => {
        if (error) {
          setError(true);
          setErrorMessage(error?.response?.message || "Erro inesperado, tente novamente mais tarde")
          setTimeout(() => { setError(false) }, 4000);
        }
      });
  }

  const getCupos = () => {
    return apiClient.httpInstance.get(`tours/${tour.id}`, {
      params: {
        date: reservationTour.date
      }
    }).then(response => {
      if (response.status === 200) {
        const latestTour = {
          ...tour,
          availables_cupos: response.data.availables_cupos,
          cupos: response.data.cupos
        }
        return latestTour
      }
    })
      .catch((error) => console.log(error));
  }

  const changeStepAndAddQuantity = async quantityList => {
    const newTour = await getCupos()
    const quantity = Math.min(Number(quantityList), newTour.availables_cupos)
    setTour(newTour)
    setQuantities(Number(quantity) - 1, 'quantityAdult', 'add')
    setStep(2)
  }

  const getDate = async date => {
    if (date) {
      apiClient.httpInstance.get(url, {
        params: {
          date: date
        }
      }).then(response => {
        if (response.status === 200) {
          setTour(response.data)
        }
      })
        .catch((error) => console.log(error));
    }
  }

  const isDisabled = () => {
    if (reservationTour.date !== null && tour.availables_cupos > 0) {
      return false
    }
    return true
  }

  useEffect(() => {
    getToursById(url);
    getDateAvailable(urlDatesUnvaliable);
    changeDate(new Date(`${location.state?.dateTour} 00:00:00`))
  }, [url, urlDatesUnvaliable]);

  if (loading) {
    return <>
      {step !== 3 ?
        <>
          {error && (
            <Alert className="mb-5" variant="filled" severity="error">
              {errorMessage}
            </Alert>
          )}
          <BreadCrumbs items={bradecrumbsConfig} />
          <div className="px-7 pt-6">
            <div className="flex items-center justify-between mt-2 text-2xl text-blue">
              <span>Comprar</span>
            </div>
            <div className="mt-4 bg-white text-blue font-poppins" style={{ borderRadius: '10px' }}>
              <div className="flex p-4 text-xl text-blue font-poppins">
                <div className="hidden md:block">
                  <img src={tour.banner ? `${process.env.REACT_APP_BASE_IMAGE_URL}/${tour.banner}` : null} width="60" className="mr-3 rounded-full" alt="" />
                </div>
                <div className="flex-auto space-y-1">
                  <div className="text-base font-medium">
                    {tour.name}
                  </div>
                  <div className="text-xs font-medium">
                    {tour.region}
                  </div>
                  <div className="flex items-center text-sm font-medium text-gray">
                    <Icon className="mr-2">local_activity</Icon> {reservationTour.quantityAdult + reservationTour.quantityKid} de {tour.availables_cupos < 0 ? 0 : tour.availables_cupos} disponíveis
                  </div>
                </div>
                <div className="flex-auto text-right">
                  <DatePicker
                    label="Data"
                    disabled={step === 2 ? true : false}
                    value={reservationTour.date !== null ? `${reservationTour.date} 00:00:00` : null}
                    shouldDisableDate={(date) => !disableDates(date)}
                    onChange={date => changeDate(date)}
                  />
                </div>
              </div>
              {step === 1 ?
                <ContentStep1
                  {...props}
                  typeTour={reservationTour.typeTour}
                  setTypeTour={changeValue}
                />
                :
                <ContentStep2
                  {...props}
                  tour={tour}
                  setQuantities={setQuantities}
                  changeValue={changeValue}
                  reservationTour={reservationTour}
                />
              }
            </div>

            {step === 1 ?
              <div className="flex justify-end space-x-5">
                <Button
                  textcolor="#212121"
                  padding="6px 15px"
                  width="100px"
                  variant="outlined"
                  onClick={() => history.push('/admin/reservas/passeios')}
                  bgColor="transparent"
                >
                  Voltar
                </Button>
                <Button
                  textcolor="#fff"
                  padding="6px 15px"
                  width="200px"
                  disabled={isDisabled()}
                  onClick={() => { changeStepAndAddQuantity(quantityList) }}
                  customcolor={props.theme.color}
                >
                  Próximo passo
                </Button>
              </div>
              :
              <>
                <div className="flex space-x-5 py-3 justify-end">
                  <Button
                    textcolor="#212121"
                    padding="6px 15px"
                    width="260px"
                    disabled={reservationTour.quantityAdult > 0 ? false : true}
                    variant="outlined"
                    onClick={() => addCart('cart')}
                    bgColor="transparent"
                  >
                    Adicionar reserva ao carrinho
                  </Button>
                </div>
                <div className="flex space-x-5 pb-3 justify-end">
                  <Button
                    textcolor="#fff"
                    padding="6px 15px"
                    width="200px"
                    disabled={reservationTour.quantityAdult > 0 ? false : true}
                    onClick={() => addCart('finish')}
                    customcolor={props.theme.color}
                  >
                    Continuar a compra
                  </Button>
                </div>
              </>
            }
          </div>
        </>
        :
        <>
          <FinishBuy {...props} type="tour" backPage={() => setStep(2)} />
        </>
      }
    </>
  } else {
    return <>
    </>
  }
}

export default BuyTour;