import { useState, useEffect } from 'react';
import BreadCrumbs from '@components/Breadcrumbs';
import DashboardCard from './partials/DashboardCard';
import { useHistory } from "react-router-dom";
import Table from '@components/Table';
import Button from '@components/Button';
import DatePicker from '@components/DatePicker';
import Select from '@components/Select';
import TextField from '@components/TextField';
import NumberFormat from 'react-number-format';
import Icon from '@material-ui/core/Icon';
import { apiClient } from '@services/api';
import { format, sub, isValid } from 'date-fns';
import ChooseProductModal from '../reservation/product/ChooseProductModal';
import SelectSearch from 'react-select';
import CircularProgress from '@material-ui/core/CircularProgress';
import Moment from 'react-moment';
import * as Helpers from '../../../../../utils/helpers';
import { number } from 'prop-types';

const permissionsSeller = ['operacao'];
const permissionsPartner = ['parceiro'];
const permissions = ['administrador', 'gerente', 'supervisor', 'operacao'];

const getFrom = () => {
  return Helpers.getFirstDay()
}

const getTo = () => {
  const today = new Date()

  return format(today, "yyyy-MM-dd")
}

const Index = (props) => {
  const user = JSON.parse(localStorage.getItem('user')).data

  document.title = `Dashboard - ${process.env.REACT_APP_NAME}`
  const history = useHistory();
  const [open, setOpen] = useState(false)
  const [loading, setLoading] = useState(false);
  const [changeView, setChangeView] = useState(0)
  const [from_date, setFromDate] = useState(getFrom())
  const [to_date, setToDate] = useState(getTo())

  const [company_id, setCompanyId] = useState(3);
  const [seller_id, setSellerId] = useState(user?.permission !== 'Administrador' ? user?.id : '');
  const [seller, setSeller] = useState(user?.permission !== 'Administrador' ? user?.name : '');
  const [sellerCustomId, setSellerCustomId] = useState(user?.permission !== 'Administrador' ? user?.typeable_id : '');
  const [sellers, setSellers] = useState([]);
  const [partner_seller_id, setPartnerId] = useState('');
  const [partner_seller_type, setPartnerType] = useState('');
  const [partner, setPartner] = useState('');
  const [partners, setPartners] = useState([]);
  const [pre_month, setPreMonth] = useState(format(new Date(), "MM"))
  const [companies, setCompanies] = useState([]);
  const { urlCompanies } = '/companies';

  const [pre_year, setPreYear] = useState(format(new Date(), "yyyy"))

  const yearIntervalOptions = [
    {
      value: '2021',
      title: '2021'
    },
    {
      value: '2022',
      title: '2022'
    },
    {
      value: '2023',
      title: '2023'
    },
    {
      value: '2024',
      title: '2024'
    },
  ]

  const monthOptions = [
    {
      value: '01',
      title: 'Janeiro'
    },
    {
      value: '02',
      title: 'Fevereiro'
    },
    {
      value: '03',
      title: 'Março'
    },
    {
      value: '04',
      title: 'Abril'
    },
    {
      value: '05',
      title: 'Maio'
    },
    {
      value: '06',
      title: 'Junho'
    },
    {
      value: '07',
      title: 'Julho'
    },
    {
      value: '08',
      title: 'Agosto'
    },
    {
      value: '09',
      title: 'Setembro'
    },
    {
      value: '10',
      title: 'Outubro'
    },
    {
      value: '11',
      title: 'Novembro'
    },
    {
      value: '12',
      title: 'Dezembro'
    },
  ]

  /* Valores Total de Vendas */
  const [totalSales, setTotalSales] = useState({
    total: 0,
    last_period: 0,
    categories: [],
    series: [
      {
        color: `${process.env.REACT_APP_SELLER_COLOR}`,
        data: 0,
        label: 'Passeios',
      },
      {
        color: `${process.env.REACT_APP_LOGISTIC_COLOR}`,
        data: 0,
        label: 'Traslados'
      },
      {
        color: `${process.env.REACT_APP_MANAGER_COLOR}`,
        data: 0,
        label: 'Combos'
      }
    ],
  })

  /* Valores Total em Vendas */
  const [totalBySeller, setTotalBySeller] = useState({
    total: 0,
    series: [{}]
  })

  const [totalFree, setTotalFree] = useState({
    total: 0,
    last_period: 0,
    categories: [],
    series: [
      {
        color: `${process.env.REACT_APP_SELLER_COLOR}`,
        data: 0,
        label: 'Passeios',
      },
      {
        color: `${process.env.REACT_APP_LOGISTIC_COLOR}`,
        data: 0,
        label: 'Traslados'
      },
      {
        color: `${process.env.REACT_APP_MANAGER_COLOR}`,
        data: 0,
        label: 'Combos'
      }
    ],
  })

  const [totalCommission, setTotalCommission] = useState({
    total: 0,
    series: [{}],
  })

  const [totalAlocated, setTotalAlocated] = useState({
    total: 0,
    series: [{}],
  })

  const [totalTicket, setTotalTicket] = useState({
    total: 0,
    last_period: 0,
    categories: [],
    series: [
      {
        color: `${process.env.REACT_APP_SELLER_COLOR}`,
        data: 0,
        label: 'Passeios',
      },
      {
        color: `${process.env.REACT_APP_LOGISTIC_COLOR}`,
        data: 0,
        label: 'Traslados'
      },
      {
        color: `${process.env.REACT_APP_MANAGER_COLOR}`,
        data: 0,
        label: 'Combos'
      }
    ],
  })

  const [totalCupos, setTotalCupos] = useState({
    total: 0,
    last_period: 0,
    categories: [],
    series: [
      {
        color: `${process.env.REACT_APP_SELLER_COLOR}`,
        data: 0,
        label: 'Passeios',
      },
      {
        color: `${process.env.REACT_APP_LOGISTIC_COLOR}`,
        data: 0,
        label: 'Traslados'
      },
      {
        color: `${process.env.REACT_APP_MANAGER_COLOR}`,
        data: 0,
        label: 'Combos'
      }
    ],
  })

  const [totalBudgets, setTotalBudgets] = useState({
    total: 0,
    last_period: 0,
    categories: [],
    series: [
      {
        color: `${process.env.REACT_APP_SELLER_COLOR}`,
        data: 0,
        label: 'Passeios',
      },
      {
        color: `${process.env.REACT_APP_LOGISTIC_COLOR}`,
        data: 0,
        label: 'Traslados'
      },
      {
        color: `${process.env.REACT_APP_MANAGER_COLOR}`,
        data: 0,
        label: 'Combos'
      }
    ],
  })

  let url = '/dashboard';

  //Oculta menu lateral
  useEffect(() => {
    props.setShowMenu(false)
  }, []);

  const changeDate = (year, month) => {
    let d = format(new Date(), "dd")
    let y = year
    let m = month
    let lastDayMonth = year === '2024' ? [31, 29, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31] : [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31]

    setFromDate(y + '-' + m + '-01')
    let m_current = format(new Date(), "MM") == '01' ? '01' : format(new Date(), "MM") - 0;
    if (month == m_current) {
      setToDate(y + '-' + m + '-' + d)
    } else {
      setToDate(y + '-' + m + '-' + lastDayMonth[m - 1])
    }
  }

  const setOption = (ev) => {
    setSeller(ev.name)
    setSellerId(ev.value)
    setSellerCustomId(ev.userId)
  }

  const setOptionPartner = (ev) => {
    setPartner(ev.name)
    setPartnerId(ev.value)
    setPartnerType(ev.type)
  }

  const bradecrumbsConfig = [
    {
      title: 'Início',
    }
  ]

  const monthNames = ["", "Jan", "Fev", "Mar", "Abr", "Mai", "Jun",
    "Jul", "Ago", "Set", "Out", "Nov", "Dez"
  ];

  const getDashboard = (url, from_date, to_date, seller_id, partner_seller_id, partner_seller_type, company_id) => {
    setLoading(true)
    let defaults = {
      total: 0,
      last_period: 0,
      categories: [],
      series: [
        {
          color: `${process.env.REACT_APP_SELLER_COLOR}`,
          data: 0,
          label: 'Passeios',
        },
        {
          color: `${process.env.REACT_APP_LOGISTIC_COLOR}`,
          data: 0,
          label: 'Traslados'
        },
        {
          color: `${process.env.REACT_APP_MANAGER_COLOR}`,
          data: 0,
          label: 'Combos'
        }
      ],
    }
    setTotalCommission(defaults)
    setTotalFree(defaults)
    setTotalSales(defaults)
    setTotalTicket(defaults)
    setTotalBySeller(defaults)
    setTotalCommission(defaults)
    setTotalAlocated(defaults)
    setTotalCupos(defaults)
    setTotalBudgets(defaults)

    const lettersName = (name) => {
      if (name) {
        var arr = name?.split(' ');
        let ret = ''
        if (arr[1] && (arr[1]?.toLowerCase() === 'de' || arr[1]?.toLowerCase() === 'da' || arr[1]?.toLowerCase() === 'do')) {
          ret = arr[0][0].toUpperCase() + arr[2][0].toUpperCase()
        } else if (arr[0] && arr[1]) {
          ret = arr[0][0].toUpperCase() + arr[1][0].toUpperCase()
        } else {
          ret = arr[0] ? arr[0][0].toUpperCase() : ''
        }
        if (arr[3]) {
          ret = ret + arr[2][0].toUpperCase()
        }

        return ret
      }
    }

    apiClient
      .httpInstance.get(url + `?from_date=${from_date}&to_date=${to_date}&seller_id=${seller_id}&partner_seller_id=${partner_seller_id}&partner_seller_type=${partner_seller_type}&company=${company_id}`)
      .then(async (response) => {
        if (response.status === 200) {

          (user?.typeable_type != 'App\\Models\\Seller' || seller_id == '' || partner_seller_id != '') && setTotalFree({
            total: response.data.statistics.totalCommission.free_total + response.data.statistics.totalCommission.free_total_transfer + response.data.statistics.totalCommission.free_total_combo,
            last_period: response.data.statistics.totalSales.last_period,
            categories: [],
            series: [
              {
                color: `${process.env.REACT_APP_SELLER_COLOR}`,
                data: response.data.statistics.totalCommission.free_total.toFixed(2),
                label: 'Passeios',
              },
              {
                color: `${process.env.REACT_APP_LOGISTIC_COLOR}`,
                data: response.data.statistics.totalCommission.free_total_transfer.toFixed(2),
                label: 'Traslados'
              },
              {
                color: `${process.env.REACT_APP_MANAGER_COLOR}`,
                data: response.data.statistics.totalCommission.free_total_combo.toFixed(2),
                label: 'Combos'
              }
            ],
          })

          setTotalCommission({
            total: response.data.statistics.totalCommission.commission_total + response.data.statistics.totalCommission.commission_total_transfer + response.data.statistics.totalCommission.commission_total_combo,
            last_period: response.data.statistics.totalSales.last_period,
            categories: [],
            series: [
              {
                color: `${process.env.REACT_APP_SELLER_COLOR}`,
                data: response.data.statistics.totalCommission.commission_total.toFixed(2),
                label: 'Passeios',
              },
              {
                color: `${process.env.REACT_APP_LOGISTIC_COLOR}`,
                data: response.data.statistics.totalCommission.commission_total_transfer.toFixed(2),
                label: 'Traslados'
              },
              {
                color: `${process.env.REACT_APP_MANAGER_COLOR}`,
                data: response.data.statistics.totalCommission.commission_total_combo.toFixed(2),
                label: 'Combos'
              }
            ],
          })

          setTotalAlocated({
            total:
              response.data.statistics.totalAllocatedReservations.allocated.scheduled
              + response.data.statistics.totalAllocatedReservations.allocated.tours
              + response.data.statistics.totalAllocatedReservations.allocated.transfers
              + response.data.statistics.totalAllocatedReservations.deallocated.tours
              + response.data.statistics.totalAllocatedReservations.deallocated.transfers,
            last_period: response.data.statistics.totalSales.last_period,
            categories: [],
            series: [
              {
                color: `${process.env.REACT_APP_SELLER_COLOR}`,
                data: response.data.statistics.totalAllocatedReservations.allocated.scheduled,
                label: 'Confirmadas',
              },
              {
                color: `${process.env.REACT_APP_LOGISTIC_COLOR}`,
                data: response.data.statistics.totalAllocatedReservations.allocated.tours + response.data.statistics.totalAllocatedReservations.allocated.transfers,
                label: 'Agendadas'
              },
              {
                color: `${process.env.REACT_APP_MANAGER_COLOR}`,
                data: response.data.statistics.totalAllocatedReservations.deallocated.tours + response.data.statistics.totalAllocatedReservations.deallocated.transfers,
                label: 'Pendentes'
              }
            ],
          })

          seller_id == '' && setTotalTicket({
            total: response.data.statistics.totalSalesByPartner?.tours || response.data.statistics.totalCommission.commission_total || 0,
            last_period: response.data.statistics.totalSales.last_period,
            categories: [],
            series: [
              {
                color: `${process.env.REACT_APP_SELLER_COLOR}`,
                data: response.data.statistics.totalSalesByPartner?.tours ? response.data.statistics.totalSalesByPartner?.tours.toFixed(2) : response.data.statistics.totalCommission?.commission_total.toFixed(2) || 0,
                label: 'Passeios',
              }
            ],
          })

          setTotalBudgets({
            total: response.data.statistics.totalBudgets.total,
            last_period: response.data.statistics.totalSales.last_period,
            categories: [],
            series: [
              {
                color: `${process.env.REACT_APP_SELLER_COLOR}`,
                data: response.data.statistics.totalBudgets.converted,
                label: 'Conversões',
              },
              {
                color: `${process.env.REACT_APP_LOGISTIC_COLOR}`,
                data: response.data.statistics.totalBudgets.budget,
                label: 'Orçamentos'
              },
              {
                color: `${process.env.REACT_APP_MANAGER_COLOR}`,
                data: response.data.statistics.totalBudgets.pending,
                label: 'Pendentes'
              }
            ],
          })

          /* Valores Total de Vendas */
          setTotalSales({
            total: response.data.statistics.totalSales.total,
            last_period: response.data.statistics.totalSales.last_period,
            categories: [],
            series: [
              {
                color: `${process.env.REACT_APP_SELLER_COLOR}`,
                data: response.data.statistics.totalSales.tours,
                label: 'Passeios',
              },
              {
                color: `${process.env.REACT_APP_LOGISTIC_COLOR}`,
                data: response.data.statistics.totalSales.transfers,
                label: 'Traslados'
              },
              {
                color: `${process.env.REACT_APP_MANAGER_COLOR}`,
                data: response.data.statistics.totalSales.combos,
                label: 'Combos'
              }
            ],
          })

          let newSeriesSellers = []
          let newLabelSeriesSellers = []
          let topDescription = []
          let counter = 0
          let oldSeriesSellers = response.data.statistics.totalSalesBySeller.commissions
          oldSeriesSellers = oldSeriesSellers.sort(function (a, b) {
            return a.sellerName < b.sellerName ? -1 : a.sellerName > b.sellerName ? 1 : 0;
          })

          oldSeriesSellers.forEach((element) => {
            newSeriesSellers[lettersName(element.sellerName)] = lettersName(element.sellerName)
            newLabelSeriesSellers.push(element.sellerName)
            topDescription[element.sellerName] = element.sellerName
            counter++
          });

          let newValuesSellers = []
          let oldValuesSellers = response.data.statistics.totalSalesBySeller.commissions

          for (let i = 0; i < oldValuesSellers.length; i++) {
            const { totalCommission } = await getReserveBySellerSimple(
              oldValuesSellers[i].sellerId,
              'seller',
              {
                from_date: null,
                to_date: null,
                year: pre_year,
                month: pre_month,
                status_reservation: 'all',
                status_commission: 'all',
                travelType: 'tour',
                company: 3,
              },
              '',
              '',
              ''
            )

            newValuesSellers.push(parseFloat(totalCommission).toFixed(2))
          }

          const newTotalValuesSellers = newValuesSellers.reduce((a, b) => Number(a) + Number(b), 0)

          setTotalBySeller({
            total: props?.theme?.permission != 'operacao' ? newTotalValuesSellers : newTotalValuesSellers,
            last_period: response.data.statistics.totalSalesBySeller.last_period,
            categories: counter > 0 ? Object.keys(newSeriesSellers) : ['Total'],
            topDescription: counter > 0 ? Object.keys(topDescription) : ['Total'],
            series: [
              {
                color: `${process.env.REACT_APP_SELLER_COLOR}`,
                name: "Total",
                data: newValuesSellers.length ? newValuesSellers : [response.data.statistics.totalCommission.commission_total],
                label: null,
                description: topDescription,
              },
            ]
          })

          let newSeriesCupos = []
          let topDescriptionCupos = []
          let counterCupos = 0
          let oldSeriesCupos = response.data.statistics.totalCuposByTravel.cupos
          oldSeriesCupos = oldSeriesCupos.sort(function (a, b) {
            return a.travel_name < b.travel_name ? -1 : a.travel_name > b.travel_name ? 1 : 0;
          })

          oldSeriesCupos.forEach((element) => {
            newSeriesCupos[lettersName(element.travel_name)] = lettersName(element.travel_name)
            if (!topDescriptionCupos[element.travel_name])
              topDescriptionCupos[element.travel_name] = element.travel_name;
            counterCupos++
          });

          let newValuesCupos = []
          let sumTotal = 0
          let oldValuesCupos = response.data.statistics.totalCuposByTravel.cupos
          oldValuesCupos.forEach((element) => {
            sumTotal += parseInt(element.cupos)
            newValuesCupos.push(parseInt(element.cupos))
          });

          setTotalCupos({
            total: sumTotal,
            last_period: response.data.statistics.totalCuposByTravel.last_period,
            categories: Object.keys(newSeriesCupos),
            topDescription: Object.keys(topDescriptionCupos),
            series: [
              {
                color: `${process.env.REACT_APP_SELLER_COLOR}`,
                name: "Total",
                data: newValuesCupos,
                label: null,
                description: topDescriptionCupos,
              },
            ]
          })

          if (seller !== '' || partner_seller_id != '') {
            getReserveBySeller(
              sellerCustomId ? sellerCustomId : partner_seller_id,
              seller ? 'seller' : 'partner',
              {
                from_date: null,
                to_date: null,
                year: pre_year,
                month: pre_month,
                status_reservation: 'all',
                status_commission: 'all',
                travelType: 'tour',
                company: 3,
              },
              response.data.statistics.totalCommission.free_total_transfer,
              response.data.statistics.totalCommission.free_total_combo,
              response.data.statistics.totalCuposByTravel.last_period
            )
            seller && getReserveBySeller(
              sellerCustomId ? sellerCustomId : partner_seller_id,
              seller ? 'seller' : 'partner',
              {
                from_date: null,
                to_date: null,
                year: pre_year,
                month: pre_month,
                status_reservation: 'all',
                status_commission: 'all',
                travelType: 'transfer',
                company: 3,
              },
              response.data.statistics.totalCommission.free_total_transfer,
              response.data.statistics.totalCommission.free_total_combo,
              response.data.statistics.totalCuposByTravel.last_period
            )
          } else {
            setLoading(false)
          }
        }
      }
      )
      .catch((error) => {
        console.log(error)
        setLoading(false)
      });
  }

  const getSellers = () => {
    apiClient.httpInstance.get(`/users?seller=true&status=true&pageSize=500`).then(response => {
      if (response.status === 200) {
        let options = [{ value: '', name: '', label: 'Todos', userId: '' }]
        response.data.data.map((element) => {
          options.push({ value: element.user_id, name: element.name, label: element.name, userId: element.id })
        })
        setSellers(options)
      }
    })
      .catch(error => {
        if (error) {

        }
      });
  }

  const getPartners = () => {
    apiClient.httpInstance.get(`/agencies_sellers`).then(response => {
      if (response.status === 200) {
        let options = [{ value: '', name: '', label: 'Todos' }]
        response.data.map((element) => {
          options.push({ value: element.id, name: element.name, label: element.name + `(${element.type === 'seller' ? 'V' : 'A'})`, type: element.type })
        })
        setPartners(options)
      }
    })
      .catch(error => {
        if (error) {

        }
      });
  }

  const getCompanies = url => {
    apiClient.httpInstance.get(`/companies?pageSize=500`).then(response => {
      if (response.status === 200) {
        let companies = response.data.data.sort(function (a, b) {
          return a.surname < b.surname ? -1 : a.surname > b.surname ? 1 : 0;
        })

        setCompanies(companies);
      }
    })
      .catch(error => {
        if (error) {
          console.log(error)
        }
      });
  }

  const getReserveBySeller = async (idUser, typeUser, params, totalFreeTransfer, totalCombo, lastPeriod) => {

    const tours_ = await getTours()

    var path = `/sellers/get/all_sellers/${typeUser}/${idUser}/sales`;
    let totalFreeValue = 0
    let totalCommission = 0
    apiClient.httpInstance.get(path, {
      params: {
        ...params,
        pageSize: 10000
      }
    }).then(response => {
      if (response.status === 200) {
        const data = response.data.data.map(obj => ({
          ...obj,
          select: false
        }))
        response.data.data = data

        for (let i = 0; i < data.length; i++) {
          var element = data[i];

          const brute_value = showFreeValue(element.reservation_brute_value, element, tours_)

          if (element.status !== 'canceled') {
            if (typeUser === 'seller') {
              let totalOperationalCost = 0;
              let partnerComission = element.partner_seller_id && element.partner_seller_type ? element.percentage_commission || 0 : element.percentage_commission_partner || 0
              let totalOperationalCostPartner = (brute_value - parseFloat(element.operational_cost_total || 0)) * partnerComission / 100 || 0

              //Novo cálculo
              if (element.tour_date < '2023-09-15') {
                //Comissões anteriores a 15/09/2023
                if (element.partner_seller_id && element.partner_seller_type) {
                  totalOperationalCost = parseInt(element.operational_cost_total)
                } else if (element.partner_seller_id) {
                  if (parseInt(element.operational_cost_total_sale) > 0) {
                    totalOperationalCost = parseFloat(element.operational_cost_total_sale)
                  } else {
                    totalOperationalCost = parseFloat(element.operational_cost_total_sale) + parseFloat(element.operational_cost_total)
                  }
                } else {
                  totalOperationalCost = parseFloat(element.operational_cost_total_sale) + parseFloat(element.operational_cost_total)
                }
              } else {
                //Comissões posteriores a 15/09/2023, novo cálculo com separação do valor de venda e de parceiro em cada passeio
                if (element.partner_seller_id && element.partner_seller_type) {
                  totalOperationalCost = parseInt(element.operational_cost_total || 0)
                } else if (element.partner_seller_id) {
                  if (parseInt(element.operational_cost_total_sale) > 0) {
                    totalOperationalCost = parseFloat(element.operational_cost_total_sale) + totalOperationalCostPartner
                  } else {
                    totalOperationalCost = parseFloat(element.operational_cost_total)
                  }
                } else {
                  totalOperationalCost = parseFloat(element.operational_cost_total_sale)
                }
              }

              if (element.type === 'tour') {
                totalFreeValue += parseFloat(brute_value) - totalOperationalCost
              } else if (element.type === 'transfer') {
                totalFreeValue += parseFloat(brute_value) - parseFloat(element.operational_cost_reserve)
              }
              //Se existir parceiro, subtrai do valor livre
              if (element.partner_seller_id /* && element.partner_seller_type */) {
                if (element.type === 'tour') {
                  totalFreeValue -= (brute_value - totalOperationalCost) * element.percentage_commission_partner / 100
                }
              }

              if (element.type === 'tour') {
                if (element.percentage_commission_region && element.percentage_commission_region !== null) {
                  let partnerComissionCalc = element.partner_seller_id && element.partner_seller_type ? element.percentage_commission : element.percentage_commission_partner
                  totalCommission += Number(((brute_value - totalOperationalCost) - ((brute_value - totalOperationalCost) * (partnerComissionCalc || 0) / 100)) * (element.percentage_commission_region ? element.percentage_commission_region : element.max_percentage_commission) / 100) || 0
                } else if (element.max_percentage_commission && element.max_percentage_commission !== null) {

                  let totalOperationalCostCalc = element.partner_seller_id && element.partner_seller_type ? parseFloat(element.operational_cost_total) : element.partner_seller_id ? parseInt(element.operational_cost_total_sale) > 0 ? parseFloat(element.operational_cost_total_sale) : parseFloat(element.operational_cost_total_sale) + parseFloat(element.operational_cost_total) : parseFloat(element.operational_cost_total)
                  totalCommission += ((brute_value - totalOperationalCostCalc) * element.max_percentage_commission / 100)
                }
              } else if (element.type === 'transfer') {
                totalCommission += Number(element.percentage_commission_region)
              }
            } else {
              if (element.partner_commission_type === true) {
                totalFreeValue += parseFloat(brute_value - element.operational_cost_total)
                if (element.type === 'tour') {
                  if (element.percentage_commission && element.percentage_commission !== null) {
                    totalCommission += ((brute_value - element.operational_cost_total) * element.percentage_commission / 100)
                  }
                } else if (element.type === 'transfer') {
                  totalCommission += Number(element.percentage_commission)
                }
              } else {
                totalFreeValue += parseFloat(brute_value)
                if (element.type === 'tour') {
                  if (element.percentage_commission && element.percentage_commission !== null) {
                    totalCommission += ((brute_value) * element.percentage_commission / 100)
                  }
                } else if (element.type === 'transfer') {
                  totalCommission += Number(element.percentage_commission)
                }
              }
            }
          }
        }

        if (params?.travelType === 'tour') {
          setTotalFree({
            total: totalFreeValue + totalFreeTransfer + totalCombo,
            last_period: lastPeriod,
            categories: [],
            series: [
              {
                color: `${process.env.REACT_APP_SELLER_COLOR}`,
                data: totalFreeValue?.toFixed(2),
                label: 'Passeios',
              },
              {
                color: `${process.env.REACT_APP_LOGISTIC_COLOR}`,
                data: totalFreeTransfer?.toFixed(2),
                label: 'Traslados'
              },
              {
                color: `${process.env.REACT_APP_MANAGER_COLOR}`,
                data: totalCombo?.toFixed(2),
                label: 'Combos'
              }
            ],
          })
          setTotalTicket({
            total: totalCommission,
            last_period: '',
            categories: [],
            series: [
              {
                color: `${process.env.REACT_APP_SELLER_COLOR}`,
                data: totalCommission?.toFixed(2),
                label: 'Passeios',
              },
            ],
          })
        }

        if (params?.travelType === 'transfer') {

          setTotalTicket((prevState) => {
            return {
              total: totalCommission + prevState.total,
              last_period: '',
              categories: [],
              series: [
                {
                  color: `${process.env.REACT_APP_SELLER_COLOR}`,
                  data: prevState.total?.toFixed(2),
                  label: 'Passeios',
                },
                {
                  color: `${process.env.REACT_APP_LOGISTIC_COLOR}`,
                  data: totalCommission?.toFixed(2),
                  label: 'Translados',
                }
              ],
            }
          })
        }

        setLoading(false)
      }
    })
      .catch((error) => console.log(error));
  }

  const getTours = async () => {
    const response = await apiClient.httpInstance.get(`/tours?pageSize=500`)

    if (response.status === 200) {
      return response.data.data
    }
  }

  const getReserveBySellerSimple = async (idUser, typeUser, params, totalFreeTransfer, totalCombo, lastPeriod) => {

    const tours_ = await getTours()

    var path = `/sellers/get/all_sellers/${typeUser}/${idUser}/sales`;
    let totalFreeValue = 0
    let totalCommission = 0
    const response = await apiClient.httpInstance.get(path, {
      params: {
        ...params,
        pageSize: 10000
      }
    })

    if (response.status === 200) {
      const data = response.data.data.map(obj => ({
        ...obj,
        select: false
      }))
      response.data.data = data

      for (let i = 0; i < data.length; i++) {
        var element = data[i];

        const brute_value = showFreeValue(element.reservation_brute_value, element, tours_)

        if (element.status !== 'canceled') {
          if (typeUser === 'seller') {
            let totalOperationalCost = 0;
            let partnerComission = element.partner_seller_id && element.partner_seller_type ? element.percentage_commission || 0 : element.percentage_commission_partner || 0
            let totalOperationalCostPartner = (brute_value - parseFloat(element.operational_cost_total || 0)) * partnerComission / 100 || 0

            //Novo cálculo
            if (element.tour_date < '2023-09-15') {
              //Comissões anteriores a 15/09/2023
              if (element.partner_seller_id && element.partner_seller_type) {
                totalOperationalCost = parseInt(element.operational_cost_total)
              } else if (element.partner_seller_id) {
                if (parseInt(element.operational_cost_total_sale) > 0) {
                  totalOperationalCost = parseFloat(element.operational_cost_total_sale)
                } else {
                  totalOperationalCost = parseFloat(element.operational_cost_total_sale) + parseFloat(element.operational_cost_total)
                }
              } else {
                totalOperationalCost = parseFloat(element.operational_cost_total_sale) + parseFloat(element.operational_cost_total)
              }
            } else {
              //Comissões posteriores a 15/09/2023, novo cálculo com separação do valor de venda e de parceiro em cada passeio
              if (element.partner_seller_id && element.partner_seller_type) {
                totalOperationalCost = parseInt(element.operational_cost_total || 0)
              } else if (element.partner_seller_id) {
                if (parseInt(element.operational_cost_total_sale) > 0) {
                  totalOperationalCost = parseFloat(element.operational_cost_total_sale) + totalOperationalCostPartner
                } else {
                  totalOperationalCost = parseFloat(element.operational_cost_total)
                }
              } else {
                totalOperationalCost = parseFloat(element.operational_cost_total_sale)
              }
            }

            if (element.type === 'tour') {
              totalFreeValue += parseFloat(brute_value) - totalOperationalCost
            } else if (element.type === 'transfer') {
              totalFreeValue += parseFloat(brute_value) - parseFloat(element.operational_cost_reserve)
            }
            //Se existir parceiro, subtrai do valor livre
            if (element.partner_seller_id /* && element.partner_seller_type */) {
              if (element.type === 'tour') {
                totalFreeValue -= (brute_value - totalOperationalCost) * element.percentage_commission_partner / 100
              }
            }

            if (element.type === 'tour') {
              if (element.percentage_commission_region && element.percentage_commission_region !== null) {
                let partnerComissionCalc = element.partner_seller_id && element.partner_seller_type ? element.percentage_commission : element.percentage_commission_partner
                totalCommission += Number(((brute_value - totalOperationalCost) - ((brute_value - totalOperationalCost) * (partnerComissionCalc || 0) / 100)) * (element.percentage_commission_region ? element.percentage_commission_region : element.max_percentage_commission) / 100) || 0
              } else if (element.max_percentage_commission && element.max_percentage_commission !== null) {

                let totalOperationalCostCalc = element.partner_seller_id && element.partner_seller_type ? parseFloat(element.operational_cost_total) : element.partner_seller_id ? parseInt(element.operational_cost_total_sale) > 0 ? parseFloat(element.operational_cost_total_sale) : parseFloat(element.operational_cost_total_sale) + parseFloat(element.operational_cost_total) : parseFloat(element.operational_cost_total)
                totalCommission += ((brute_value - totalOperationalCostCalc) * element.max_percentage_commission / 100)
              }
            } else if (element.type === 'transfer') {
              totalCommission += Number(element.percentage_commission_region)
            }
          } else {
            if (element.partner_commission_type === true) {
              totalFreeValue += parseFloat(brute_value - element.operational_cost_total)
              if (element.type === 'tour') {
                if (element.percentage_commission && element.percentage_commission !== null) {
                  totalCommission += ((brute_value - element.operational_cost_total) * element.percentage_commission / 100)
                }
              } else if (element.type === 'transfer') {
                totalCommission += Number(element.percentage_commission)
              }
            } else {
              totalFreeValue += parseFloat(brute_value)
              if (element.type === 'tour') {
                if (element.percentage_commission && element.percentage_commission !== null) {
                  totalCommission += ((brute_value) * element.percentage_commission / 100)
                }
              } else if (element.type === 'transfer') {
                totalCommission += Number(element.percentage_commission)
              }
            }
          }
        }
      }

      return {
        totalFreeValue,
        totalCommission
      }
    }
  }

  const showFreeValue = (value, element, tours_) => {
    if (element?.region_id != '81' && element?.region_id != '79') {
      return value
    }
    const tour_date = new Date(element?.tour_date)
    const today = new Date()
    if ((tour_date < today) && !(tour_date > new Date('2024-02-29') && tour_date < new Date('2024-04-01')) && element?.region_id != '79') {
      return value
    } else {
      const selectedTour = tours_ && tours_?.find(tour => tour?.name == element?.travel_name)

      if (!selectedTour) return value

      return Number(selectedTour?.price) * (Number(element?.adults_total) + Number(element?.kids_total))
    }
  }

  const cleanFilters = () => {
    setPreYear(format(new Date(), "yyyy"))
    setPreMonth(format(new Date(), "MM") == '01' ? 12 : format(new Date(), "MM") - 1)
    setFromDate(getFrom())
    setToDate(getTo())
    setSeller('')
    setSellerId('')
    setPartnerId('')
    setPartnerType('')
    setPartner('')
    setChangeView(changeView + 1)
  }

  useEffect(() => {
    getDashboard(url, from_date, to_date, seller_id, partner_seller_id, partner_seller_type, company_id);
  }, [url, changeView]);

  useEffect(() => {
    getSellers()
    getPartners()
    getCompanies(urlCompanies);
  }, [url]);

  return <>
    <div className="px-7">
      <div className="flex items-center justify-start mt-2 text-2xl text-blue mb-3">
        <Icon className="mr-2">
          dashboard
        </Icon>
        <span>Dashboard</span>
      </div>
      <div className="flex flex-wrap items-center py-4 space-x-3 space-x-reverse">
        <div className="flex-none mt-2 md:mt-0 mr-3">
          <Select
            label="Ano"
            width="100px"
            defaultValue={pre_year}
            withoutAll={true}
            onChange={(e) => {
              setPreYear(e.target.value)
              changeDate(e.target.value, pre_month)
            }
            }
            items={{
              field: {
                value: 'value',
                text: 'title'
              },
              data: yearIntervalOptions
            }}
          />
        </div>
        <div className="flex-none mt-3 md:mt-0">
          <Select
            label="Mês"
            width="150px"
            defaultValue={pre_month}
            withoutAll={true}
            onChange={(e) => {
              setPreMonth(e.target.value)
              changeDate(pre_year, e.target.value)
            }
            }
            items={{
              field: {
                value: 'value',
                text: 'title'
              },
              data: monthOptions
            }}
          />
        </div>
        {permissions.includes(props?.theme?.permission) && <>
          {(!permissionsSeller.includes(props?.theme?.permission) && !permissionsPartner.includes(props?.theme?.permission)) && <>
            <div className="flex-none sm:p-2 mt-2 md:mt-0 sm:w-40 min-w-max" style={{ borderRadius: '20px', border: '1px solid #8080806e', padding: '9px 0px' }}>
              <SelectSearch
                styles={customStyles}
                options={sellers}
                width='230px'
                isDisabled={partner !== '' ? true : false}
                value={seller === '' ? { value: '', name: 'Vendedor', label: 'Vendedor' } : { value: seller_id, name: seller, label: seller }}
                onChange={(ev) => setOption(ev)}
                name="seller"
              />
            </div>
            <div className="flex-none sm:p-2 mt-2 md:mt-0 sm:w-40 min-w-max" style={{ borderRadius: '20px', border: '1px solid #8080806e', padding: '9px 0px' }}>
              <SelectSearch
                styles={customStyles}
                options={partners}
                width='230px'
                isDisabled={seller !== '' ? true : false}
                value={partner === '' ? { value: '', name: 'Parceiro', label: 'Parceiro' } : { value: partner_seller_id, name: partner, label: partner }}
                onChange={(ev) => setOptionPartner(ev)}
                name="partner"
              />
            </div>
          </>}
          <div className="flex-none sm:p-2 m-2 md:m-0 sm:w-20 min-w-max">
            <Button
              textcolor="#fff"
              margintop="4px"
              padding="6px 30px"
              color="primary"
              size="large"
              width="150px"
              type="button"
              variant="text"
              className="cursor-pointer"
              onClick={() => getDashboard(url, from_date, to_date, seller_id, partner_seller_id, partner_seller_type, company_id)}
            >
              {!loading ?
                'Filtrar'
                :
                <div className="flex-1 text-center">
                  <CircularProgress size={20} />
                </div>}
            </Button>
            <Button
              textcolor="#fff"
              margintop="4px"
              marginleft="8px"
              padding="6px 30px"
              color="primary"
              size="large"
              width="150px"
              type="button"
              variant="text"
              className="cursor-pointer"
              onClick={() => cleanFilters()}
            >
              Limpar
            </Button>
          </div>
        </>}

      </div>
      <div className="py-4">
        <div className="flex flex-wrap items-center space-x-2">
          <div className="flex-1">
            {totalFree.series.length > 0 &&
              <DashboardCard
                {...props}
                data={totalFree}
                typeChart="pie"
                title="Total livre"
                width="w-80"
                value={
                  <NumberFormat
                    value={totalFree.total}
                    displayType={'text'}
                    decimalSeparator={","}
                    decimalScale={2}
                    fixedDecimalScale={true}
                    decimalPrecision={2}
                    thousandSeparator={"."}
                    isNumericString
                    prefix={`${process.env.REACT_APP_CURRENCY} `}
                    renderText={(value) => <div className="flex items-center pr-4 space-x-5">{value}</div>}
                  />
                }
                color={totalFree.total < 0 ? "#F44336" : "#4CAF50"}
                height="200"
              />
            }
          </div>
          {/* Gráfico Total de Vendas */}
          <div className="flex-1">
            {totalSales.series.length > 0 &&
              <DashboardCard
                {...props}
                data={totalSales}
                typeChart="pie"
                title="Total de vendas"
                width="w-80"
                value={
                  <NumberFormat
                    value={totalSales.total}
                    displayType={'text'}
                    decimalSeparator={","}
                    decimalScale={0}
                    fixedDecimalScale={false}
                    thousandSeparator={"."}
                    isNumericString
                    renderText={(value) => <div className="flex items-center pr-4 space-x-5">{value}</div>}
                  />
                }
                color={totalSales.total < 0 ? "#F44336" : "#4CAF50"}
                height="200"
              />
            }
          </div>
          {/* Gráfico Total em Vendas */}
          {(!permissionsPartner.includes(props?.theme?.permission) && totalBySeller.series.length > 0 && seller_id == '') &&
            <div className="flex-1">

              <DashboardCard
                {...props}
                data={totalBySeller}
                showTooltip={true}
                title="Comissão Total"
                value={
                  <NumberFormat
                    value={totalBySeller.total}
                    displayType={'text'}
                    decimalSeparator={","}
                    decimalScale={2}
                    fixedDecimalScale={true}
                    decimalPrecision={2}
                    thousandSeparator={"."}
                    isNumericString
                    prefix={`${process.env.REACT_APP_CURRENCY} `}
                    renderText={(value) => <div className="flex items-center pr-4 space-x-5">{value}</div>}
                  />
                }
                typeChart="bar"
                color={totalBySeller.last_period < 0 ? "#F44336" : "#4CAF50"}
                height="200"
                width="460"
              />
            </div>}
          {/* Gráfico Comissão total apenas para vendedor */}
          {(!permissionsPartner.includes(props?.theme?.permission) && totalBySeller.series.length > 0 && seller_id != '') &&
            <div className="flex-1 my-2">
              <DashboardCard
                {...props}
                data={totalTicket}
                typeChart="pie"
                title="Comissão Total"
                width="w-80"
                value={
                  <NumberFormat
                    value={totalTicket.total}
                    displayType={'text'}
                    decimalSeparator={","}
                    decimalScale={2}
                    fixedDecimalScale={true}
                    thousandSeparator={"."}
                    isNumericString
                    prefix={`${process.env.REACT_APP_CURRENCY} `}
                    renderText={(value) => <div className="flex items-center pr-4 space-x-5">{value}</div>}
                  />
                }
                color={totalTicket.last_period < 0 ? "#F44336" : "#4CAF50"}
                height="200"
              />
            </div>}
          {!permissionsSeller.includes(props?.theme?.permission) && totalTicket.series.length > 0 &&
            <div className="flex-1 my-2">
              <DashboardCard
                {...props}
                data={seller_id == '' ? totalTicket
                  : {
                    total: 0,
                    last_period: 0,
                    categories: [],
                    series: [
                      {
                        color: `${process.env.REACT_APP_SELLER_COLOR}`,
                        data: 0,
                        label: 'Passeios',
                      },
                    ],
                  }}
                typeChart="pie"
                title="Comissão Parceiros"
                width="w-80"
                value={
                  seller_id == '' ?
                    <NumberFormat
                      value={totalTicket.total}
                      displayType={'text'}
                      decimalSeparator={","}
                      decimalScale={2}
                      fixedDecimalScale={true}
                      thousandSeparator={"."}
                      isNumericString
                      prefix={`${process.env.REACT_APP_CURRENCY} `}
                      renderText={(value) => <div className="flex items-center pr-4 space-x-5">{value}</div>}
                    />
                    : 0
                }
                color={totalTicket.last_period < 0 ? "#F44336" : "#4CAF50"}
                height="200"
              />
            </div>}
        </div>
        <div className="flex flex-wrap items-center space-x-3">
          <div className="flex-1 my-2">
            {totalCupos.series.length > 0 &&
              <DashboardCard
                {...props}
                data={totalCupos}
                typeChart="bar"
                //horizontal={true}
                showTooltip={true}
                title="Cupos vendidos"
                value={
                  <NumberFormat
                    value={totalCupos.total}
                    displayType={'text'}
                    decimalSeparator={","}
                    decimalScale={0}
                    fixedDecimalScale={false}
                    thousandSeparator={"."}
                    isNumericString
                    renderText={(value) => <div className="flex items-center pr-4 space-x-5">{value}</div>}
                  />
                }
                color={totalCupos.last_period < 0 ? "#F44336" : "#4CAF50"}
                height="200"
                width="460"
              />
            }
          </div>
          <div className="flex-1">
            {totalBudgets.series.length > 0 &&
              <DashboardCard
                {...props}
                data={totalBudgets}
                typeChart="pie"
                title="Orçamentos e conversões"
                value={totalBudgets.total}
                color={totalBudgets.total < 0 ? "#F44336" : "#4CAF50"}
                height="200"
              />
            }
          </div>
        </div>
      </div>
      <div className="mt-4 bg-white text-blue font-poppins" style={{ borderRadius: '10px' }}>
        <div className="flex items-center justify-between p-4 text-xl text-blue font-poppins">
          <span>Vendas do período</span>
          {permissions.includes(props?.theme?.permission) && <Button
            textcolor="#FFF"
            padding="6px 15px"
            width="200"
            customcolor={props.theme.color}
            type="button"
            onClick={() => setOpen(true)}
          >
            <Icon className="mr-2">add</Icon> Nova reserva
          </Button>}
          <ChooseProductModal
            open={open}
            close={() => setOpen(false)}
            changeTourPage={() => history.push('/admin/reservas/passeios')}
            changeTransferPage={() => history.push('/admin/reservas/traslados')}
            changeCombosPage={() => history.push('/admin/reservas/combos')}
            {...props}
          />
        </div>
        <div className="flex justify-center pb-3 text-center">
          <Table
            path={'/dashboard/sales'}
            params={{ from_date, to_date, seller_id, partner_seller_id, partner_seller_type }}
            dataResult="sales"
            onRowClick={(props) => {
              const reserve = props.row;
              history.push(`/admin/reservas/detalhe/${reserve.sale_id}`);
            }}
            columns={[
              {
                headerName: 'Referência',
                headerClassName: 'super-app-theme--header',
                field: 'references',
                width: 170
              },
              {
                headerName: 'Data da venda',
                headerClassName: 'super-app-theme--header',
                field: 'created_at',
                width: 200,
                renderCell: (params) => {
                  const row = params.row;
                  return (<Moment format="DD/MM/YYYY" date={row.created_at} />)
                }
              },
              {
                headerName: 'Cliente',
                headerClassName: 'super-app-theme--header',
                field: 'name',
                width: 650
              },
              {
                headerName: 'Tipo de produto',
                headerClassName: 'super-app-theme--header',
                field: 'type',
                width: 250,
                renderCell: (params) => {
                  const row = params.row;
                  const selectType = {
                    tour: {
                      bg: '#D8212FCC',
                      text: 'Passeio'
                    },
                    transfer: {
                      bg: '#4480A8E5',
                      text: 'Traslado'
                    },
                    translate: {
                      bg: '#4480A8E5',
                      text: 'Traslado'
                    },
                    out: {
                      bg: '#4480A8E5',
                      text: 'Out'
                    },
                    in: {
                      bg: '#009C86E5',
                      text: 'In'
                    }
                  }
                  return (<div className="flex items-center pr-4 space-x-5">
                    {row.type &&
                      <div className="flex items-center justify-center rounded-full h-8 w-24 text-white" style={{ background: row.transfer_type ? selectType[row.transfer_type].bg : selectType[row.type].bg }}>
                        {row.transfer_type ? selectType[row.transfer_type].text : selectType[row.type].text}
                      </div>
                    }
                  </div>);
                }
              },
              {
                headerName: 'Quantidade',
                headerClassName: 'super-app-theme--header',
                field: 'quantity',
                width: 145,
              },
              {
                headerName: 'Valor',
                headerClassName: 'super-app-theme--header',
                field: 'price',
                renderCell: (params) => {
                  const row = params.row;
                  return (
                    <NumberFormat
                      value={Number(row.price) || 0}
                      displayType={'text'}
                      decimalSeparator={","}
                      decimalScale={2}
                      fixedDecimalScale={true}
                      decimalPrecision={2}
                      thousandSeparator={"."}
                      isNumericString
                      prefix={`${process.env.REACT_APP_CURRENCY} `}
                      renderText={(value) => <div className="flex items-center pr-4 space-x-5">{value}</div>}
                    />);
                }
              },
              {
                headerName: 'Pagamento',
                headerClassName: 'super-app-theme--header',
                field: 'payment_status',
                width: 130,
                renderCell: (params) => {
                  const row = params.row;
                  const status = {
                    paid: {
                      text: 'Realizado',
                      icon: 'check_circle'
                    },
                    cancelled: {
                      text: 'Pendente',
                      icon: 'do_disturb_on'
                    },
                    accomplished: {
                      text: 'Realizado',
                      icon: 'check_circle'
                    },
                    pending: {
                      text: 'Pendente',
                      icon: 'do_disturb_on'
                    },
                    partial: {
                      text: 'Parcial',
                      icon: 'access_time'
                    }
                  }
                  return (<div className="flex items-center pr-4 space-x-5">
                    {row.payment_status &&
                      <><Icon className="ml-2">{status[row.payment_status].icon}</Icon> </>
                    }
                  </div>);
                }
              },
            ]}
            rows={[]}
          />
        </div>
      </div>
    </div>
  </>
}

export default Index;

const customStyles = {
  menu: (provided, state) => ({
    ...provided,
    width: state.selectProps.width,
    color: state.selectProps.menuColor,
    padding: 20,
    display: 'flex',
    fontSize: '14px'
  }),

  control: (_, { selectProps: { width } }) => ({
    width: width,
    display: 'flex',
  }),

  singleValue: (provided, state) => {
    const opacity = state.isDisabled ? 0.5 : 1;
    const transition = 'opacity 300ms';

    return {
      ...provided,
      opacity,
      transition,
      fontSize: '14px'
    };
  }
}
