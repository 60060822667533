import languageFile from '@config/language';
import { format } from 'date-fns';

export function slugfy(str) {
  if (!str) return '';

  str = str.replace(/^\s+|\s+$/g, ''); // trim
  str = str.toLowerCase();

  var from = "ãàáäâẽèéëêìíïîõòóöôùúüûñç·/_,:;";
  var to   = "aaaaaeeeeeiiiiooooouuuunc------";
  for (var i=0, l=from.length ; i<l ; i++) {
    str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));
  }

  str = str.replace(/[^a-z0-9 -]/g, '')
    .replace(/\s+/g, '-')
    .replace(/-+/g, '-');

  return str;
}

export function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function dateLocale(dateString, locale = 'pt-BR') {
  return new Date(dateString).toLocaleDateString(locale);
}

export const getFirstDay = (day) => {
  var today = day? new Date(day) : new Date();
  var firstDay = new Date(today.getFullYear(), today.getMonth(), 1);
  firstDay = format(firstDay, "yyyy-MM-dd");
  return firstDay;
}

export const getLastDay = (day) => {
  var today = day? new Date(day) : new Date();
  var lastDay = new Date(today.getFullYear(), today.getMonth() + 1, 0);
  lastDay = format(lastDay, "yyyy-MM-dd");
  return lastDay;
}

export function translator(idiom = 'en', key, subkey) {
  const language = languageFile[idiom];

  if (language && language[key]) {
    return language[key][subkey];
  }

  return key;
}

export function range(min, max, step = 1) {
  var list = [];

  for (var i = min; i <= max; i += step) {
      list.push(i);
  }

  return list;
};
