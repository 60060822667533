import Select from '@components/Select';
import { range } from '@utils/helpers';

const DiscountInput = (props) => {
  const value = Number(props.discountLimit) > 100 ? 100 : Number(props.discountLimit)
  const limit = props.discountLimit ? value : 100
  const percentages = range(0, limit , 5).map((v) => ({ value: v, text: `${v}%`}));
  //percentages.splice(3, 0, { value: 12, text: '12%'})

  if(limit >= 7) percentages.splice(2, 0, { value: 7, text: '7%'})

  if(limit >= 12 && (props?.typeService === "tour" || props?.showNewPercent == true)) percentages.splice(4, 0, { value: 12, text: '12%'})

  if(limit >= 14)   percentages.splice(5,0,{value: 14, text: '14%'})

  return <Select
    {...props}
    withoutAll
    items={{
      field: {
        value: 'value',
        text: 'text'
      },
      data: percentages
    }}
  />
}

export default DiscountInput;
