import Button from '@components/Button'
import DiscountInput from '@components/DiscountInput'
import InputCurrency from '@components/InputCurrency'
import InputFile from '@components/InputFile'
import Select from '@components/Select'
import TextField from '@components/TextField'
import Icon from '@material-ui/core/Icon'
import { apiClient } from '@services/api'
import { useEffect, useState } from 'react'

const Form = (props) => {
  const tourPeriodOptions = [
    {
      value: "halfday",
      label: "Meio período"
    },
    {
      value: "fullday",
      label: "Integral"
    }
  ]
  const dayPeriodOptions = [
    {
      value: "AM",
      label: "Antes do meio-dia"
    },
    {
      value: "PM",
      label: "Depois do meio-dia"
    }
  ]
  const [regionOptions, setRegionOptions] = useState([])
  const getRegions = () => {
    apiClient
      .httpInstance.get('/regions').then(response => {
        if (response.status === 200) {
          setRegionOptions(response.data);
        }
      });
  }
  useEffect(() => {
    getRegions()
  }, [])

  return <>
    <form onSubmit={props.submit}>
      <div className="p-4 mt-4 bg-white rounded-xl border-1">
        <div className="mb-4">
          <InputFile changeFile={props.changeFile} banner={props.tour.banner} />
        </div>
        <div className="flex mb-4 space-x-3">
          <div className="flex-1">
            <TextField
              className="inputRounded"
              label="Nome do passeio"
              variant="outlined"
              type="text"
              name="name"
              value={props.tour.name}
              onChange={(ev) => props.changeState(ev)}
              required
            />
          </div>
          <div className="flex-1">
            <Select
              withoutAll
              label="região do passeio"
              value={props.tour.region}
              onChange={(ev) => props.changeState(ev)}
              name="region"
              defaultValue={props.isEditing ? " " : ''}
              items={{
                field: {
                  value: 'id',
                  text: 'name'
                },
                data: regionOptions
              }}
              required
            />
          </div>
          <div className="flex-1">
            <Select
              withoutAll
              label="Período do passeio"
              defaultValue={props.isEditing ? " " : ''}
              value={props.tour.tour_period}
              onChange={(ev) => props.changeState(ev)}
              name="tour_period"
              items={{
                field: {
                  value: 'value',
                  text: 'label'
                },
                data: tourPeriodOptions
              }}
            />
          </div>
          <div className="flex-1">
            <Select
              disabled={props.tour.tour_period === 'fullday' ? true : false}
              withoutAll
              label="Período do dia"
              value={props.tour.tour_period === 'fullday' ? null : props.tour.day_period}
              onChange={(ev) => props.changeState(ev)}
              name="day_period"
              defaultValue={props.isEditing ? " " : ''}
              items={{
                field: {
                  value: 'value',
                  text: 'label'
                },
                data: dayPeriodOptions
              }}
            />
          </div>
        </div>
        <div className="flex mb-4 space-x-1 pb-8">
          <TextField
            className="inputRounded"
            placeholder="Descrição"
            variant="outlined"
            multiline
            rows={2}
            name="description"
            value={props.tour.description}
            onChange={(ev) => props.changeState(ev)}
          />
        </div>
        <span className="text-2xl text-blue ">Passeio em grupo</span>

        <div className="grid grid-cols-2 gap-4 py-8">
          <div>
            <p className="text-gray">Adulto</p>
            <div className="flex mt-7 mb-4 space-x-3">
              <InputCurrency
                className="inputRounded"
                label="Valor"
                variant="outlined"
                name="price"
                value={props.tour.price}
                onChange={(ev) => props.changeState(ev)}
              />
              <div className="flex-none">
                <DiscountInput
                  typeService="tour"
                  label="Desconto"
                  name="adult_discount_percentage"
                  value={props.tour.adult_discount_percentage}
                  onChange={(ev) => props.changeState(ev)}
                  width="70px"
                />
              </div>
              <InputCurrency
                className="inputRounded"
                label="Custo operacional Parceiro"
                variant="outlined"
                name="operational_cost"
                value={props.tour.operational_cost}
                onChange={(ev) => props.changeState(ev)}
              />
              <InputCurrency
                className="inputRounded"
                label="Custo operacional Venda"
                variant="outlined"
                name="operational_cost_sale"
                value={props.tour.operational_cost_sale}
                onChange={(ev) => props.changeState(ev)}
              />
            </div>
          </div>
          <div>
            <p className="text-gray">Criança</p>
            <div className="flex mt-7 mb-4 space-x-3">
              <InputCurrency
                className="inputRounded"
                label="Valor"
                variant="outlined"
                name="kids_price"
                value={props.tour.kids_price}
                onChange={(ev) => props.changeState(ev)}
              />
              <div className="flex-none">
                <DiscountInput
                  typeService=""
                  showNewPercent={true}
                  label="Desconto"
                  name="kid_discount_percentage"
                  value={props.tour.kid_discount_percentage}
                  onChange={(ev) => props.changeState(ev)}
                  width="70px"
                />
              </div>
              <InputCurrency
                className="inputRounded"
                label="Custo operacional Parceiro"
                variant="outlined"
                name="kid_operational_cost"
                value={props.tour.kid_operational_cost}
                onChange={(ev) => props.changeState(ev)}
              />
              <InputCurrency
                className="inputRounded"
                label="Custo operacional Venda"
                variant="outlined"
                name="kid_operational_cost_sale"
                value={props.tour.kid_operational_cost_sale}
                onChange={(ev) => props.changeState(ev)}
              />
            </div>
          </div>
        </div>
        <p className="text-2xl text-blue mb-4">Passeio privado</p>
        {props.tour.private_values.map((obj, idx) => {
          return <div className="grid grid-cols-6 gap-3 pb-4 items-center">
            <div className="col-span-5">
              <div className="flex mt-4 space-x-3">
                <div className="flex-1">
                  <InputCurrency
                    className="inputRounded"
                    label="Valor"
                    variant="outlined"
                    name="value"
                    value={obj.value}
                    onChange={(ev) => props.changeStatePrivate(idx, ev)}
                    required={Number(obj.value) > 0 ? true : false}
                  />
                </div>
                <div className="flex-1">
                  <InputCurrency
                    className="inputRounded"
                    label="Custo operacional Parceiro"
                    variant="outlined"
                    name="cost"
                    value={obj.cost}
                    onChange={(ev) => props.changeStatePrivate(idx, ev)}
                    required={Number(obj.value) > 0 ? true : false}
                  />
                </div>
                <div className="flex-1">
                  <InputCurrency
                    className="inputRounded"
                    label="Custo operacional Venda"
                    variant="outlined"
                    name="cost_sale"
                    value={obj.cost_sale}
                    onChange={(ev) => props.changeStatePrivate(idx, ev)}
                    required={Number(obj.value) > 0 ? true : false}
                  />
                </div>
                <div className="flex-none">
                  <TextField
                    disabled
                    className="inputRounded"
                    label="Mín. de pessoas"
                    variant="outlined"
                    type="text"
                    name="min_people"
                    value={obj.min_people}
                    onChange={(ev) => props.changeStatePrivate(idx, ev)}
                    width="160px"
                    required={Number(obj.value) > 0 ? true : false}
                  />
                </div>
                <div className="flex-none">
                  <TextField
                    disabled={props.tour.private_values.length - 1 > idx}
                    className="inputRounded"
                    label="Máx. de pessoas"
                    variant="outlined"
                    type="number"
                    name="max_people"
                    value={obj.max_people}
                    min={obj.min_people}
                    onChange={(ev) => props.changeStatePrivate(idx, ev)}
                    width="160px"
                    required={Number(obj.value) > 0 ? true : false}
                  />
                </div>
                <div className="flex-none">
                  <DiscountInput
                    typeService=""
                    label="Desconto"
                    name="discount"
                    showNewPercent={true}
                    value={obj.discount}
                    onChange={(ev) => props.changeStatePrivate(idx, ev)}
                    width="70px"
                  />
                </div>
              </div>
            </div>
            <div>
              {idx > 0 &&
                <div className="flex mt-4 space-x-3">
                  <div className="flex-1">
                    <Icon className="mr-2 cursor-pointer text-red-500" onClick={() => props.removePrivate(idx)}>delete</Icon>
                  </div>
                </div>
              }
            </div>
          </div>
        })}
        <div className="flex ">
          <div className="flex-1 mb-3">
            <span className="text-gray cursor-pointer" onClick={() => props.addPrivate()}>+ Adicionar intervalo</span>
          </div>
        </div>

        <div className="grid grid-cols-3 gap-3 pb-4 items-center">
          <div className="flex-1 mb-3 mr-3">
            <p className="text-2xl text-blue mb-4 mt-5">Venda no site</p>
            <TextField
              className="inputRounded"
              label="quantidade mínima"
              variant="outlined"
              type="number"
              name="min_quantity"
              value={props.tour.min_quantity}
              onChange={(ev) => props.changeState(ev)}
            />
          </div>


          <div className="flex-1 mb-3 ml-3">
            <p className="text-2xl text-blue mb-4 mt-5">ID do passeio no site</p>
            <TextField
              className="inputRounded"
              label="ID"
              variant="outlined"
              type="number"
              name="id_site"
              value={props.tour.id_site}
              onChange={(ev) => props.changeState(ev)}
            />
          </div>

        </div>

      </div>
      <div className="text-right pb-4">
        <Button
          textcolor="#FFF"
          padding="6px 15px"
          width="200"
          customcolor={props.theme.color}
          type="submit"
        >
          Salvar
        </Button>
      </div>
    </form>
  </>
}

export default Form
