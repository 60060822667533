import '@assets/css/Admin/components/table.scss';
import { DataGrid } from '@material-ui/data-grid';
import { makeStyles } from '@material-ui/styles';
import { apiClient } from '@services/api';
import * as React from 'react';
import { useHistory, useRouteMatch } from "react-router-dom";


const exmp = {
  "current_page": 1,
  "data": [],
  "first_page_url": "http://127.0.0.1:8000/api/service_integrations?page=1",
  "from": 1,
  "last_page": 1,
  "last_page_url": "http://127.0.0.1:8000/api/service_integrations?page=1",
  "links": [
    {
      "url": null,
      "label": "&laquo; Anterior",
      "active": false
    },
    {
      "url": "http://127.0.0.1:8000/api/service_integrations?page=1",
      "label": "1",
      "active": true
    },
    {
      "url": null,
      "label": "Próximo &raquo;",
      "active": false
    }
  ],
  "next_page_url": null,
  "path": "http://127.0.0.1:8000/api/service_integrations",
  "per_page": "15",
  "prev_page_url": null,
  "to": 2,
  "total": 2
}

function loadServerRows(path, page, pageSize, params, loading) {
  return apiClient.httpInstance.get(path, {
    params: {
      ...params,
      pageSize,
      page
    }
  }).then(response => {
    if (response.status === 200) {
      if (path === '/companys_integrations') {
        const total = exmp;
        total.data = response.data;
        return total;
      } else {
        return response.data;
      }
    }
  })
    .catch((error) => console.log(error));
}

const useStyles = makeStyles({
  root: {
    '& .super-app-theme--header': {
      fontWeight: 'bold',
      fontFamily: 'Poppins',
      fontSize: '14px',
      color: `${process.env.REACT_APP_SECONDARY_COLOR}`,
    },
    '& .MuiDataGrid-columnHeaderTitle': {
      fontWeight: 'bold',
    },
    '& .super-app-theme--cell': {
      color: `${process.env.REACT_APP_SECONDARY_COLOR}`,
      cursor: 'pointer',
      fontFamily: 'Poppins',
      fontSize: '14px',
    },
  },
});

export default function ServerPaginationGrid(props) {
  const { path } = useRouteMatch();
  const [blockClick, setBlockClick] = React.useState(true);
  const columns = props.columns;
  const getPath = props.path;
  const history = useHistory();
  const classes = useStyles();
  const onRowClick = props.onRowClick === undefined ? (param) => {
    if (blockClick) {
      history.push(`${path}/detalhe/${param.id}`);
    }
  } : props.onRowClick;

  const onCellClick = (param, e) => {
    const fields = columns.filter(function (item) {
      if (item?.preventRedirect) {
        return item;
      }
    }).map(function (item) {
      return item.field;
    });

    if (fields.includes(param.field)) {
      e.stopPropagation();
    }
  };

  let params = props.params;
  const [page, setPage] = React.useState(0);
  const [total, setTotal] = React.useState(0);
  const [pageSize, setPageSize] = React.useState(15);
  const [rows, setRows] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [pageHeight, setPageHeight] = React.useState(115)

  const handlePageChange = (params) => {
    if (params && params.page === undefined) {
      setPage(Number(params));
    } else {
      setPage(Number(params.page));

    }
  };

  const handlePageSizeChange = (params) => {
    const sizePage = rows.length > params.pageSize ? params.pageSize : rows.length
    setPageHeight(rows.length > 0 ? 115 + 52 * sizePage : 300)
  };

  const handleGetResult = (getPath, page, pageSize, params) => {
    let active = true;

    (async () => {
      setLoading(true);
      const result = props.loadRows ? await props.loadRows(getPath, page + 1, pageSize, params) : await loadServerRows(getPath, page + 1, pageSize, params);
      const newRows = props?.dataResult ? result?.[props?.dataResult] && result?.[props?.dataResult]?.data ? result?.[props?.dataResult]?.data : result?.data : result?.data
      const total = props?.dataResult ? result?.[props.dataResult]?.total : result?.total
      setTotal(total);
      if (!active) {
        return;
      }
      setRows(newRows ? newRows : []);
      const sizePage = newRows?.length > pageSize ? pageSize : newRows?.length
      setPageHeight(newRows?.length > 0 ? 115 + 52 * sizePage : 300)
      setLoading(false);
    })();

    return () => {
      active = false;
    };
  }

  React.useEffect(() => {
    handleGetResult(getPath, page, pageSize, params);
  }, [getPath, page, pageSize]);

  React.useEffect(() => {
    let timeOutId = null;
    if (props?.checkLoading) {
      if (!loading) {
        timeOutId = setTimeout(() => handleGetResult(getPath, page, pageSize, params), 500);
      }
    } else {
      timeOutId = setTimeout(() => handleGetResult(getPath, page, pageSize, params), 500);
    }
    //const timeOutId = setTimeout(() => handleGetResult(getPath, page, pageSize, params), 500);
    return () => clearTimeout(timeOutId);
  }, [getPath, params]);

  React.useEffect(() => {
    if (params?.pageSize) {
      setPageSize(params?.pageSize);
      setPageHeight(params?.pageSize)
    }
    if (props.refresh === true) {
      handleGetResult(getPath, page, pageSize, params);
    }
  }, [props.refresh, getPath, page, pageSize]);

  return (
    <div style={{ background: '#fff', height: pageHeight, width: '100%' }} className={classes.root}>
      <DataGrid
        {...props}
        rows={rows}
        columns={columns}
        pagination
        // localeText={ptBR.props.MuiDataGrid.localeText}
        pageSize={pageSize}
        rowCount={total}
        rowsPerPageOptions={[5, 10, 15, 30]}
        disableSelectionOnClick={true}
        getRowClassName={(params) =>
          `super-app-theme--cell`
        }
        onRowClick={onRowClick}
        onCellClick={onCellClick}
        paginationMode="server"
        onPageSizeChange={handlePageSizeChange}
        onPageChange={(params) => { handlePageChange(params) }}
        loading={loading}
        disableColumnMenu
        components={{
          NoRowsOverlay: () => (
            <div
              style={{
                width: "100%",
                height: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                position: "absolute"
              }}
            >
              Não encontramos resultados para a sua busca.
            </div>
          )
        }}
      />
    </div>
  );
}
